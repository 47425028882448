import React, { useState } from "react";
import { Card, CardBody, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import DonutPie from "./DonutPie";

const WidgetDonutPie = (props: any) => {
  const [menu1, setMenu1] = useState<boolean>(false);

  const series = props.series ?? [90, 10];

  const options = {
    plotOptions: {
      pie: {
        donut: {
          size: "70%"
        }
      }
    },
    dataLabels: {
      enabled: !1
    },

    labels: props.labels ?? ["Strava", "Other"],
    colors: ["#000","#038edc", "#f5f6f8", "#038e11", "#f599f8"],
    legend: {
      show: !0,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: !1,
      fontSize: "14px",
      offsetX: 0
    }
  };
  return (
    <React.Fragment>
      <Card className="card-h-100" style={{ borderWidth: "1px 0 0 0" }}>
        <CardBody>
          <div className="d-flex align-items-start">
            <div className="flex-grow-1">
              <h5 className="card-title mb-4">{props.title ?? "Activity Source"}</h5>
            </div>
          </div>
          <div id="chart-donut" className="apex-charts" dir="ltr">
            <DonutPie page={props.page} series={series} options={options} />
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default WidgetDonutPie;

import { Navigate } from "react-router-dom";

//User
import AuthenticatedDashboard from "../pages/User/DashboardPage";

//profile
import UserProfile from "../pages/Authentication/user-profile";

//Authentication pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import PublicHome from "../pages/Public/Home";
import StravaParse from "../components/Strava/StravaParse";
import PermissionsPage from "../pages/User/permissionsPage";
import Redux from "../pages/Public/Redux";
import MarketPage from "../pages/User/MarketPage";
import StatsPage from "../pages/User/StatsPage";
import WalletPage from "../pages/User/WalletPage";
import MyPurchasesPage from "../pages/User/MyPurchasesPage";

interface RouteProps {
  path: string;
  component: any;
  roles?: Array<string>
  exact?: boolean;
}

const publicRoutes: Array<RouteProps> = [
  //dashboard
  // {
  //   path: "/",
  //   exact: true,
  //   component: <Navigate to="/a" />,
  // },
  // { path: "/", component: <Home /> },
  { path: "/public", component: <>PUBLIC PAGE</> },

  { path: "*", component: <Navigate to="/" /> }
];
// Custom Public Layout
const publicRoutes2: Array<RouteProps> = [
  { path: "/", component: <PublicHome /> },
  { path: "/test-home", component: <>TEST HOME</> }
];
const userRoutes: Array<RouteProps> = [
  { path: "/dashboard", roles:['user','buyer'], component: <AuthenticatedDashboard />},
  { path: "/permissions", roles:['user','buyer'], component: <PermissionsPage /> },
  { path: "/wallet", component: <WalletPage /> },
  { path: "/transactions/user", component: <></> },
  { path: "/pages-faqs", component: <div style={{padding:80}}>HELP PAGE</div> },
  { path: "/transactions/enterpriser", component: <></> },
  { path: "/redux", component: <Redux /> },
  // //profile
  { path: "/redirect_strava/exchange_token", component: <StravaParse /> },
  { path: "/profile", component: <UserProfile /> },
  { path: "/market", roles:['buyer', 'user'], component: <MarketPage /> },
  { path: "/mypurchases", roles:['buyer'], component: <MyPurchasesPage /> },
  { path: "/stats", component: <StatsPage /> },
];

const authRoutes: Array<RouteProps> = [
  //Authentication pages
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/register", component: <Register /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
];


export { publicRoutes, userRoutes, authRoutes, publicRoutes2 };

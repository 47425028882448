import React from "react";
import { Card, CardBody, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import ReactApexChart from "react-apexcharts";

const WidgetChartTile = ({ processedData }) => {
  // const mapheight = props.page ? "30%" : "50%";
  const mapheight = processedData.series && processedData.series.length > 4 ? "50%" : "20%";

  const baroptions = {
    chart: {
      type: "bar",
      height: 50,
      sparkline: {
        enabled: !0
      }
    },
    plotOptions: {
      bar: {
        columnWidth: mapheight
      }
    },
    tooltip: {
      fixed: {
        enabled: !1
      },
      y: {
        title: {
          formatter: function() {
            return "";
          }
        }
      }
    },
    colors: [function ({ value, seriesIndex, dataPointIndex, w }) {
      if (value > -1) {
        return "#038edc";
      } else {
        return "#DC143C";
      }
    }] // "#d33e6c"
    // colors: ["#038edc"] // "#d33e6c"
  };

  const areaoptions = {
    chart: {
      height: 50,
      type: "area",
      sparkline: {
        enabled: !0
      },
      toolbar: {
        show: !1
      }
    },
    dataLabels: {
      enabled: !1
    },
    stroke: {
      curve: "smooth",
      width: 2
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: !1,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [50, 100, 100, 100]
      }
    },
    colors: ["#038edc", "transparent"]
  };

  return (
    <Card style={{minHeight: "200px"}}>
      <CardBody>
        <div className="d-flex justify-content-between">
          <div>
            <h6 className="font-size-xs text-uppercase"> {processedData["title"]} </h6>
            <h4 className="mt-4 font-weight-bold mb-2 d-flex align-items-center">
              {processedData["price"]}
            </h4>
            <div className="text-muted"> {processedData["subtitle"]} </div>
          </div>
          <div>
            {/*<UncontrolledDropdown>*/}
            {/*  <DropdownToggle tag="a" className="dropdown-toggle btn btn-light btn-sm">*/}
            {/*                                <span className="text-muted">{processedData["fotmat"]}<i*/}
            {/*                                  className="mdi mdi-chevron-down ms-1"></i></span>*/}
            {/*  </DropdownToggle>*/}
            {/*  <DropdownMenu className="dropdown-menu-end">*/}
            {/*    <DropdownItem to="#">Monthly</DropdownItem>*/}
            {/*    <DropdownItem to="#">Yearly</DropdownItem>*/}
            {/*    <DropdownItem to="#">Weekly</DropdownItem>*/}
            {/*  </DropdownMenu>*/}
            {/*</UncontrolledDropdown>*/}
          </div>
        </div>
        <div className="apex-charts mt-3">
          <ReactApexChart
            options={processedData["charttype"] === "bar" ? baroptions : areaoptions}
            series={[{ data: [...processedData["series"]] }]}
            type={processedData["charttype"]}
            className="apex-charts"
            dir="ltr"
            height="50"
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default WidgetChartTile;

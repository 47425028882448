import React from "react";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card, Container, Row } from "reactstrap";
import useTitle from "../../hooks/use-title";
import { defaultTitle } from "../../constants/defaultValues";
import PurchasesHistory from "../../components/Permissions/PurchasesHistory";

const PurchasesPage = () => {
  useTitle("My Purchases");
  // const [profile, setProfileData] = useState({});
  // const [needPermissions, setNeedPermissions] = useState(true);
  // const [isLoading, setIsLoading] = useState(false);

  // const { profile } = useSelector((state => state), shallowEqual);
  // const profileData = profile && profile.success;

  // useEffect(() => {
  // }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={defaultTitle} breadcrumbItem="My Purchases" />

          <Card
            style={{ padding: "2rem" }}
            // style={{ margin: "0" }}
          >
            <Row>
              <PurchasesHistory />
            </Row>
          </Card>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default PurchasesPage;

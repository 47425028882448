import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col sm={6}> © {new Date().getFullYear()} FitChain. All rights reserved.</Col>
            <Col sm={6}>
              {/*<div className="text-sm-end d-none d-sm-block">*/}
              {/*  Crafted with <i className="mdi mdi-heart text-danger"></i> by <Link to="https://Pichforest.com/" target="_blank" rel="noreferrer" className="text-reset">PIKEI</Link>*/}
              {/*</div>*/}
              <div className="text-sm-end d-none d-sm-block">
                Follow us on: {" "}
                <Link to="https://logikax.com" target="_blank" rel="noreferrer" className="">Logika
                  X</Link>
                ,{" "}
                <Link to="https://www.neuronai.eu/" target="_blank" rel="noreferrer"
                      className="">Neuron</Link>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;

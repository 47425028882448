import React, { useEffect, useState } from "react";
import { Alert, Button, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  deleteWallet as onDeleteWallet,
  createWallet as onCreateWallet,
  getWallet as onGetWallet
} from "../../slices/Wallet/thunk";

const WalletPrompt = () => {

  const { profile } = useSelector((state => state), shallowEqual);
  const { wallet } = useSelector((state => state), shallowEqual);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);


  const profileData = profile && profile.success;
  // const hasWallet = profile && profile.success && profile.success.wallet_id;
  const hasWallet = wallet && wallet.id;
  const colorInfo = hasWallet ? "success" : "warning";

  const dispatch = useDispatch();

  useEffect(() => {
    if (profileData.name) {
      // getWalletBalance(profileData.name)
      dispatch(onGetWallet(profileData.name));
    }
  }, [profileData.name]);

  const createWalletHandler = () => {
    if (profileData.name) {
      dispatch(onCreateWallet(profileData.name));
      // createWallet(profileData.name).then(res=>{
      //   console.log(res);
      // });
    }
  };
  const clickDeleteWalletHandler = () => {
    setDeleteModalOpen(true)
  };
  const onConfirmDeleteWallet = () => {
    if (profileData.name) {
      // deleteWalletAction(profileData.name)
      dispatch(onDeleteWallet(profileData.name));
      setDeleteModalOpen(false);
    }
  };
  const cancelDeletion = () => {
    setDeleteModalOpen(false);
  };
  return (
    <div>
      <Card style={{minHeight: "200px"}}>
        <CardBody>
          <Alert
            color={colorInfo}
            className="border-0 d-flex align-items-center"
          >
            <i className={`uil ${hasWallet?'uil-check':'uil-exclamation-triangle'} font-size-16 text-${colorInfo} me-2`}></i>
            <div className="flex-grow-1 text-truncate">

              {hasWallet && "You have an Active Wallet!"}
              {!hasWallet && "You must Create a Wallet."}
            </div>
            {/*<div className="flex-shrink-0">*/}
            {/*  <Link to="/permissions" className="text-reset text-decoration-underline"><b>Permissions</b></Link>*/}
            {/*</div>*/}
          </Alert>

          <div className="row align-items-center">
            <div className="col-sm-7">
              <div className="mt-2">
                {!hasWallet &&
                  <button to="#" className="btn btn-success"
                          onClick={createWalletHandler}
                          disabled={wallet.isLoading}
                  > Create Wallet</button>}
                {hasWallet &&
                  <button to="#" className="btn btn-danger"
                          onClick={clickDeleteWalletHandler}
                          disabled={wallet.isLoading}
                  >Delete Wallet</button>}

              </div>
            </div>
            <div className="col-sm-5">
              <i className="mdi mdi-wallet mdi-48px"></i>
            </div>
          </div>
        </CardBody>
      </Card>
      <Modal
        isOpen={deleteModalOpen}
        toggle={cancelDeletion}
        // onClosed={closeAll ? toggle : undefined}
      >
        <ModalHeader>Delete Confirmation</ModalHeader>
        <ModalBody>Are you sure you want to delete wallet?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={onConfirmDeleteWallet}>
            Delete
          </Button>{" "}
          <Button color="secondary" onClick={cancelDeletion}>
            Back
          </Button>
        </ModalFooter>
      </Modal>

    </div>
  );
};

export default WalletPrompt;

import * as url from "../helpers/url_helper";
import { ApiCore } from "./api_helper";
import config from "../config";


const api = new ApiCore();
const apiHost =  config.fitchain.API_HOST + '/api';

export const getProfile = (username) => {
  return api.get(apiHost + `/${username}/profile`);
};

export const updateProfile = (username, data) => {
  // create because expecting post backend
  return api.create(apiHost + `/${username}/profile`, {
    "accepted_terms": data.accepted_terms ?? undefined, // 1,
    "allowed_data_share": data.allowed_data_share ?? undefined, // 1,
    "strava_access": data.strava_access ?? undefined, // "a9bd14a3db5d435554d3ec0cefb155555502942e",
    "strava_refresh": data.strava_refresh ?? undefined, // "e81849c4b3e88e555949a1dede4f5555556e2bb5",
    "strava_expire": data.strava_expire ?? undefined, // 1667228587,
    "password": data.password ?? undefined, // "my_new_password",
    "password_confirmation": data.password_confirmation ?? undefined, // "my_new_password"
    "name": data.name ?? undefined // "my_new_password"
  });
};
// -----  METRICS -----
export const getMetrics = () => {
  return api.get(apiHost + `/metrics`);
};

export const getMetricGroups = () => {
  return api.get(apiHost + `/metrics/grouped`);
};

export const getSelectedMetrics = (username) => {
  return api.get(apiHost + `/${username}/metrics/authorize`);
};

export const authorizeMetrics = (username, data) => {
  // create because expecting post backend
  return api.create(apiHost + `/${username}/metrics/authorize`, data);
};

export const calculateMetricsData = (username, data) => {
  // create because expecting post backend
  return api.create(apiHost + `/${username}/transactions/data/calculate`, data);
};
export const orderSelectedData = (username, data) => {
  // create because expecting post backend
  return api.create(apiHost + `/${username}/transactions/data/order`, data);
};
export const getOrdersList = (username) => {
  return api.get(apiHost + `/${username}/transactions/data/list`);
};
export const getDownloadInfoByOrderId = (username, data) => {
  // create because expecting post backend
  return api.create(apiHost + `/${username}/transactions/data/download`, data);
};

// -----  Tokens  -----

export const orderTokens = (username, data) => {
  // create because expecting post backend
  return api.create(apiHost + `/${username}/transactions/tokens/order`, data);
};
export const getTokenOrdersList = (username) => {
  return api.get(apiHost + `/${username}/transactions/tokens/list`);
};

//  ----- WALLET  -----

export const getWalletBalance = (username) => {
  return api.get(apiHost + `/${username}/wallet`);
};
export const postWalletTopUp = (username, topUpObj) => { // using instead /transactions/tokens/order
  return api.create(apiHost + `/${username}/wallet/topup`, topUpObj);
};
export const deleteWallet = (username) => {
  return api.delete(apiHost + `/${username}/wallet`);
};
export const createWallet = (username) => {
  return api.create(apiHost + `/${username}/wallet`, {});
};

//  ----- Statistics  -----

export const getUserDashboardStatistics = (username) => {
  return api.get(apiHost + `/statistics`);
};

//  ----- STRAVA  -----

export const getStravaInfo = (username) => {
  return api.get(apiHost + `/${username}/strava`);
};
export const createStravaConnection = (username, data) => { // using instead /transactions/tokens/order
  return api.create(apiHost + `/${username}/strava`, {
    "strava_access": data.strava_access ?? undefined, // "a9bd14a3db5d435554d3ec0cefb155555502942e",
    "strava_refresh": data.strava_refresh ?? undefined, // "e81849c4b3e88e555949a1dede4f5555556e2bb5",
    "strava_expire": data.strava_expire ?? undefined, // 1667228587,
  });
};
export const deleteStravaConnection = (username) => {
  return api.delete(apiHost + `/${username}/strava`);
};

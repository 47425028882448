import React, { useEffect, useState } from "react";
import { Button, Row, Spinner } from "reactstrap";
import { getMetricGroups } from "../../api/api";
import MetricGroupItem from "./BuyMetrics/MetricGroupItem";

const BuyMetrics = (props) => {
  const [activityTypesProcessed, setActivityTypesProcessed] = useState([]);
  const [groups, setGroups] = useState([]);
  // const [clickedGroups, setClickedGroups] = useState([]);
  const { isLoading = false, onCalculate } = props;
  const isGroupClicked = groups.findIndex(g => g.isChecked) > -1;
  console.log(activityTypesProcessed);
  useEffect(() => {
    // fetch Activity Types && personal permissions
    getMetricGroups().then(async res => {
      console.log(res);
      // const data = res.count;
      // console.log(data);
      const metricGroups = res && res.metrics_grouped;
      console.log(metricGroups);

      // setActivityTypesProcessed([...metricGroups.map(mGroup => {
      //   return {
      //     ...mGroup,
      //     title: mGroup.description,
      //     isChecked: false,
      //     isTimeSpecified: false,
      //     fromDate: "2024-08-19",
      //     toDate: "2024-08-19"
      //   };
      // })]);
      // const uniqueGroups = [...new Set(metricGroups.map(m => m.domains).join(",").split(","))];
      const arr = Object.keys(metricGroups)
        .filter(v => metricGroups[v] != null)
        .map(key => ({ title: key, metrics: metricGroups[key] }));

      const uniqueGroups = [...arr];
      const processedGroups = uniqueGroups.map(g => {
        return {
          title: g.title,
          metrics: g.metrics,
          isChecked: false,
          isTimeSpecified: false,
          fromDate: null,
          toDate: null
        };
      });
      setGroups(processedGroups);
    }).catch(e => {
      const metricGroups = METRIC_DOMAINS;
      console.log(metricGroups);
      const arr = Object.keys(metricGroups)
        .filter(v => metricGroups[v] != null)
        .map(key => ({ title: key, metrics: metricGroups[key] }));

      const uniqueGroups = [...arr];
      const processedGroups = uniqueGroups.map(g => {
        return {
          title: g.title,
          metrics: g.metrics,
          isChecked: false,
          isTimeSpecified: false,
          fromDate: null,
          toDate: null //"2024-08-19"
        };
      });
      setGroups(processedGroups);
    });
    // setActivityTypesProcessed([...activityTypes.map(activity => {
    //   return {
    //     title: activity,
    //     isChecked: false,
    //     isTimeSpecified: false,
    //     fromDate: "2024-08-19",
    //     toDate: "2024-08-19"
    //   };
    // })]);
  }, []);
  console.log({ groups });

  const onClickCheckbox = (title) => {
    const activityIndex = groups.findIndex(activity => activity.title === title);
    if (activityIndex < 0) return;

    const activityToUpdate = { ...groups[activityIndex] };
    const activityUpdated = {
      ...activityToUpdate,
      isChecked: !activityToUpdate.isChecked,
      isTimeSpecified: false,
      fromDate: !activityToUpdate.isTimeSpecified ? activityToUpdate.fromDate : undefined,
      toDate: !activityToUpdate.isTimeSpecified ? activityToUpdate.toDate : undefined
    };
    const activityTypesCopy = [...groups];
    activityTypesCopy.splice(activityIndex, 1, activityUpdated);
    setGroups(activityTypesCopy);
  };
  const onClickSwitch = (title) => {
    const activityIndex = groups.findIndex(activity => activity.title === title);
    if (activityIndex < 0) return;

    const activityToUpdate = { ...groups[activityIndex] };
    const activityUpdated = {
      ...activityToUpdate,
      isTimeSpecified: !activityToUpdate.isTimeSpecified,
      fromDate: !activityToUpdate.isTimeSpecified ? activityToUpdate.fromDate : undefined,
      toDate: !activityToUpdate.isTimeSpecified ? activityToUpdate.toDate : undefined
    };
    const activityTypesCopy = [...groups];
    activityTypesCopy.splice(activityIndex, 1, activityUpdated);
    setGroups(activityTypesCopy);
  };

  const onUpdateDate = (e) => {
    const { name: title, value, id } = e.target;
    const activityIndex = groups.findIndex(activity => activity.title === title);
    if (activityIndex < 0) return;

    const activityToUpdate = { ...groups[activityIndex] };
    const activityUpdated = {
      ...activityToUpdate,
      [id]: value
    };
    const activityTypesCopy = [...groups];
    activityTypesCopy.splice(activityIndex, 1, activityUpdated);
    setGroups(activityTypesCopy);
  };

  const calculateHandler = () => {
    const data = {
      domains: groups.filter(g => g.isChecked).map(g => {
        return {
          "domain": g.title,
          "from": g.fromDate ? g.fromDate + "  00:00:00" : null,
          "to": g.toDate ? g.toDate + "  23:59:59" : null
        };
      })
    };
    props.onCalculate(data);
  };

  return (
    <>

      {/*<h5 className="font-size-13 text-uppercase text-muted mb-4"><i*/}
      {/*  className="mdi mdi-chevron-right text-primary me-1"></i> Data Permissions</h5>*/}
      <Row className={"flex flex-row"}>

        {groups.map((group, i) => <MetricGroupItem key={i}
                                                   group={group}
                                                   onClickCheckbox={onClickCheckbox.bind(this, group.title)}
                                                   onClickSwitch={onClickSwitch.bind(this, group.title)}
                                                   onUpdateDate={onUpdateDate}
        />)}
      </Row>

      <div>
        <Button onClick={calculateHandler}
                className={"px-3 mt-3"}
                color={"primary"}
                disabled={isLoading || (!isGroupClicked)}
        >
           <span>
             Calculate
           </span>
          <span style={{ visibility: isLoading ? "visible" : "hidden", marginLeft: "4px" }}>

             <Spinner size="sm">
              Loading...
            </Spinner>
           </span>
        </Button>
      </div>

    </>
  );
};

export default BuyMetrics;


const METRIC_DOMAINS = {
    "HEALTH": [
      {
        "id": 1,
        "name": "distance",
        "description": "Distance",
        "domains": [
          "HEALTH"
        ],
        "created_at": "2024-09-09 13:20:03",
        "updated_at": "2024-09-09 13:20:03"
      },
      {
        "id": 2,
        "name": "calories",
        "description": "Calories",
        "domains": [
          "HEALTH"
        ],
        "created_at": "2024-09-09 13:20:03",
        "updated_at": "2024-09-09 13:20:03"
      },
      {
        "id": 3,
        "name": "elapsed_time",
        "description": "Elapsed time",
        "domains": [
          "HEALTH",
          "CITY"
        ],
        "created_at": "2024-09-09 13:20:03",
        "updated_at": "2024-09-09 13:20:03"
      }
    ],
    "CITY": [
      {
        "id": 6,
        "name": "aerobic",
        "description": "Aerobic",
        "domains": [
          "HEALTH",
          "CITY",
          "COUNTRY",
          "SEA"
        ],
        "created_at": "2024-09-09 13:20:03",
        "updated_at": "2024-09-09 13:20:03"
      },
      {
        "id": 8,
        "name": "candence_max",
        "description": "Max of Candence",
        "domains": [
          "HEALTH",
          "CITY"
        ],
        "created_at": "2024-09-09 13:20:03",
        "updated_at": "2024-09-09 13:20:03"
      },
      {
        "id": 10,
        "name": "speed_max",
        "description": "Max of Speed",
        "domains": [
          "HEALTH",
          "CITY"
        ]
      },
      {
        "id": 11,
        "name": "elevation_gain",
        "description": "Elevation Gain",
        "domains": [
          "HEALTH",
          "CITY"
        ],
        "created_at": "2024-09-09 13:20:03",
        "updated_at": "2024-09-09 13:20:03"
      },
      {
        "id": 12,
        "name": "elevetion_loss",
        "description": "Elevation Loss",
        "domains": [
          "HEALTH",
          "CITY"
        ]
      }
    ],
    "COUNTRY": [
      {
        "id": 6,
        "name": "aerobic",
        "description": "Aerobic",
        "domains": [
          "HEALTH",
          "CITY",
          "COUNTRY",
          "SEA"
        ],
        "created_at": "2024-09-09 13:20:03",
        "updated_at": "2024-09-09 13:20:03"
      }
    ],
    "SEA":
      [
        {
          "id": 6,
          "name": "aerobic",
          "description": "Aerobic",
          "domains": [
            "HEALTH",
            "CITY",
            "COUNTRY",
            "SEA"
          ],
          "created_at": "2024-09-09 13:20:03",
          "updated_at": "2024-09-09 13:20:03"
        }]
  }
;
// Object.keys(myObject).forEach(function(key, index) {
//   myObject[key] *= 2;
// });

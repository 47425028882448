import React, { useEffect, useState } from "react";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Col, Container, Row } from "reactstrap";
import { authorizeMetrics } from "../../api/api";
import WidgetData from "../../components/Common/Widgets";
import useTitle from "../../hooks/use-title";
import Permissions from "../../components/Permissions/Permissions";
import { defaultTitle } from "../../constants/defaultValues";

const PermissionsPage = () => {
  useTitle("Permissions");
  // const [profile, setProfileData] = useState({});
  // const [needPermissions, setNeedPermissions] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // getProfile().then(d => {
    //   console.log(d);
    //   setProfileData(d);
    // }).catch(e => {
    //   console.log(e);
    // });
  }, []);

  const saveHandler = async (username,data) => {
    setIsLoading(true)
    await authorizeMetrics(username,data)

    setIsLoading(false)

    // setTimeout(()=>{
    //   // setNeedPermissions(false)
    //   setIsLoading(false)
    // },2000)
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={defaultTitle} breadcrumbItem="Permissions" />

          <Row>
            {/*<h5 className="m-1 mb-sm-0"> Permissions </h5>*/}
             <Permissions isLoading={isLoading} onSave={saveHandler} />
          </Row>

          {/*<Row> <pre>*/}
          {/*  {JSON.stringify(profile, null, 1)}*/}
          {/*  </pre>*/}
          {/*</Row>*/}


        </Container>
      </div>
    </React.Fragment>
  );
};

export default PermissionsPage;

import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import WidgetTile from "../UI/WidgetTile";
import { getUserDashboardStatistics } from "../../api/api";

const WidgetUserInfo = () => {
  const [data, setData] = useState({});
  const { total_users, total_buyers, users_allowed_access, transactions } = data;

  useEffect(() => {
    getUserDashboardStatistics().then(res => {
      if (!(res && res.total_users))
        return;
      // console.log({ res });
      setData(res);
    }).catch(e => {
      console.log(e);
    });
  }, []);

  return (
    <div>
      <Row>
        <Col xl={3} sm={6}>
          <WidgetTile icon={"uil-list-ul"} title={"Total Users"} value={total_users} />
        </Col>
        <Col xl={3} sm={6}>
          <WidgetTile icon={"uil-check-circle"} title={"Users sharing data"} value={users_allowed_access} />
        </Col>
        <Col xl={3} sm={6}>
          <WidgetTile icon={"uil-users-alt"} title={"Total Buyers"} value={total_buyers} />
        </Col>
        <Col xl={3} sm={6}>
          <WidgetTile icon={"uil-clock-eight"} title={"Transactions"} value={transactions} />
        </Col>
      </Row>
    </div>
  );
};

export default WidgetUserInfo;

import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { shallowEqual, useSelector } from "react-redux";

const CalculationModal = (props) => {
  const { isOpen, toggleModal, calculatedData, onComplete } = props;
  const toggle = () => toggleModal();
  // const toggle = () => setModal(!isOpen);

  const { wallet } = useSelector((state => state), shallowEqual);
  const wallet_credits = wallet.credits || 0;

  const hasAvailableData = calculatedData.permitted_tradable > 0;
  // const canBuy = calculatedData.can_buy
  const canBuy = hasAvailableData && ((wallet_credits - calculatedData.permitted_tradable_tokens) > 0);

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} backdrop={"static"}>
        <ModalHeader toggle={toggle}>Cost Calculation</ModalHeader>
        <ModalBody>
          <table>
            <thead>
            <td>

            </td>
            <td style={{ minWidth: "160px" }}>
              Records
            </td>
            <td style={style.cell}>
              Tokens Cost
            </td>
            </thead>
            <tbody>
            <tr>
              <td style={style.cell}>
                Total Metrics {/*Σύνολο ανεβασμένων μετρικών*/}
              </td>
              <td style={style.cell}>
                {calculatedData.potential_total}
              </td>
              <td style={style.cell}>
                {calculatedData.potential_total_tokens}
              </td>
            </tr>
            <br />
            <tr>
              <td style={style.cell}>
                Total allowed Metrics {/*Σύνολο επιτρεπόμενων μετρικών*/}
              </td>
              <td style={style.cell}>
                {calculatedData.potential_tradable} {/*potential_tradable*/}
              </td>
              <td style={style.cell}>
                {calculatedData.potential_tradable_tokens} {/*potentional_total_tokens*/}
              </td>
            </tr>
            <br />

            <tr>
              <td style={style.cell}>
                Total Selected {/* Σύνολο επιλεγμένων μετρικών */}
              </td>
              <td style={style.cell}>
                {calculatedData.permitted_tradable} {/*permitted_total*/}
              </td>
              <td style={style.cell}>
                {calculatedData.permitted_tradable_tokens} {/*permitted_total_tokens*/}
              </td>
            </tr>
            <tr>

            </tr>
            </tbody>

          </table>

          <div className={"pt-4 d-flex flex-grow-1 flex-column align-items-center justify-content-center"}>

            <div className={"p-1"}>
              Wallet - Selected cost = {wallet_credits} - {calculatedData.permitted_tradable_tokens}
            </div>
            <div className={"p-1"}>
              Remaining Tokens = {wallet_credits - calculatedData.permitted_tradable_tokens}
            </div>

            <div className={"p-2"}>
              Do you want to complete the purchase?
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="success"
                  onClick={onComplete}
                  disabled={!canBuy}
          >
            Purchase
          </Button>{" "}
          <Button color="danger" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CalculationModal;

const style = {
  cell: {
    padding: "5px"
  }
};

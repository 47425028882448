import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { withTranslation } from "react-i18next";

//import drawer

//import component
import RightSidebar from "../CommonForBoth/RightSidebar";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col, Offcanvas, DropdownItem } from "reactstrap";

//import images
import logoSm from "../../assets/images/logo-sm.png";
import logoDark from "../../assets/images/logo-dark.png";
import logoLight from "../../assets/images/logo-light.png";
import illustrator from "../../assets/images/illustrator/1.png";
import logoFitChain from "../../assets/images/fitchain-no-bg.png";


import slack from "../../assets/images/brands/slack.png";
import behance from "../../assets/images/brands/behance.png";
import dribbble from "../../assets/images/brands/dribbble.png";
import dropbox from "../../assets/images/brands/dropbox.png";
import mail_chimp from "../../assets/images/brands/mail_chimp.png";
import github from "../../assets/images/brands/github.png";

//Import Icons
import Icon from "@ailibs/feather-react-ts";
import useWindowSize from "../../hooks/useWindowSize";
import { changeBodyAttribute, manageBodyClass } from "../../slices/Layout/utils";
import WebApps from "./Header/WebApps";
import { getLoggedIn } from "../../helpers/myAuth/myAuth";
import Search from "./Header/Search";
import { leftSidebarTypes } from "../../constants/layout";
import { changeSidebarType } from "../../slices/Layout/thunk";
import { defaultTitle } from "../../constants/defaultValues";
import Logo from "../CommonForBoth/Logo";

const Header = (props: any) => {

  const [position, setPosition] = useState<string>();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [megaMenu, setmegaMenu] = useState(false);
  const [categoryMenu, setcategoryMenu] = useState(false);
  /**
   * Rightsidebar drawer
   */
  const isLoggedIn = getLoggedIn();

  const body = document.body;
  const { width } = useWindowSize();

  const toggleTopDrawer = () => {
    setPosition("right");
    setOpenDrawer(!openDrawer);
  };

  const onDrawerClose = () => {

    setOpenDrawer(false);
  };

  useEffect(() => {
    const sidebarSize = document.body.getAttribute("data-sidebar-size");
    if (width <= 992) {
      // if (sidebarSize !== "sm") {
      // changeSidebarType(leftSidebarTypes.COMPACT)
      // body.classList.toggle("sidebar-enable");
      // changeSidebarType(leftSidebarTypes.ICON);
      // manageBodyClass("sidebar-enable", "add");
      changeBodyAttribute("data-sidebar-size", "sm");
      // changeBodyAttribute("data-sidebar-size", leftSidebarTypes.ICON);
      // }
    } else {
      changeSidebarType(leftSidebarTypes.ICON);
      body.classList.toggle("sidebar-enable");

      // if (sidebarSize !== "sm") {
      //   changeSidebarType(leftSidebarTypes.ICON);
      // }
    }

  }, [width]);

  /*** Sidebar menu icon and default menu set */
  function tToggle() {
    body.classList.toggle("sidebar-enable");
    changeSidebarType(leftSidebarTypes.ICON);
    changeBodyAttribute("data-sidebar-size", "sm");

    // const width = (window && window.innerWidth) || window.screen.width;
    // if (width <= 998) {
    //   body.classList.toggle("sidebar-enable");
    //   document.body.setAttribute("data-sidebar-size", "sm");
    // } else {
    //   // document.body.setAttribute("data-sidebar-size", "md");
    // }
    // if (document.body.getAttribute("data-sidebar-size") !== leftSidebarTypes.ICON) {
    //   changeBodyAttribute("data-sidebar-size", leftSidebarTypes.COMPACT);
    // } else {
    //   changeBodyAttribute("data-sidebar-size", leftSidebarTypes.ICON);
    // }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            {/*Logo Name*/}
            <div className={"d-flex align-self-center d-block pl-5"} >
              {/*<div className={"d-flex align-self-center d-block d-lg-none"} >*/}
              <Logo />
            </div>

            {<button
              onClick={() => {
                tToggle();
              }}
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn d-block d-lg-none"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>}

          </div>

          <div className="d-flex">
            {/*{isLoggedIn && <Search />}*/}
            {/*<LanguageDropdown />*/}

            {/*<WebApps/>*/}

            {isLoggedIn && <NotificationDropdown />}

            {/*   Theme Settings    */}

            {/*<div*/}
            {/*  onClick={() => {*/}
            {/*    toggleTopDrawer();*/}
            {/*  }}*/}
            {/*  className="dropdown d-inline-block"*/}
            {/*>*/}
            {/*  <button*/}
            {/*    type="button"*/}
            {/*    className="btn header-item noti-icon right-bar-toggle "*/}
            {/*  >*/}
            {/*    <Icon*/}
            {/*      name="settings"*/}
            {/*      className="icon-sm"*/}
            {/*    />*/}
            {/*  </button>*/}
            {/*</div>*/}

            {isLoggedIn && <ProfileMenu />}
            {!isLoggedIn && <div className="d-flex justify-self-center align-self-center p-2">
              <span className="align-middle">
              <Link to="/login" title={props.t("Login")}>
                <i className="mdi mdi-logout text-muted font-size-16 align-middle me-1 font-size-20"> </i>{" "}
                {props.t("Login")}
              </Link>
            </span>
            </div>}

          </div>
        </div>
      </header>

      <Offcanvas isOpen={openDrawer} onClosed={onDrawerClose} direction={"end"}>
        <RightSidebar
          onClosed={onDrawerClose}
        />
      </Offcanvas>

    </React.Fragment>
  )
    ;
};

export default withTranslation()(Header);

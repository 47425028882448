import React, { useState } from "react";

//import Breadcrumbs
import { Container, Row } from "reactstrap";
import useTitle from "../../hooks/use-title";
import { shallowEqual, useSelector } from "react-redux";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { defaultTitle } from "../../constants/defaultValues";

const StatsPage = () => {
  useTitle("User Stats");
  const [needPermissions, setNeedPermissions] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const { profile } = useSelector((state => state), shallowEqual);
  const { wallet } = useSelector((state => state), shallowEqual);

  const saveHandler = () => {
    setIsLoading(true);
    setTimeout(() => {
      setNeedPermissions(false);
      setIsLoading(false);
    }, 2000);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}

          {profile && profile.success && profile.success.role==='user' && <>
            <Breadcrumbs title={defaultTitle} breadcrumbItem="User Stats" />
            <Row>


            </Row>

          </>}
          {/*{profile && profile.success && profile.success.role==='buyer' && <ContentUser />}*/}

        </Container>
      </div>
    </React.Fragment>
  );
};

export default StatsPage;

import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getInvoices as getInvoicesApi, addNewInvoice as addInvoiceApi, deleteInvoice as deleteInvoiceApi,
  updateInvoice as updateInvoiceApi
} from "../../helpers/fakebackend_helper";
import { deleteWallet as deleteWalletApi , getWalletBalance, createWallet as createWalletApi } from "../../api/api";

export const getWallet: any = createAsyncThunk("wallet/getWallet", async (username:string) => {
  try {
    const response = getWalletBalance(username);
    return response;
  } catch (error) {
    return error;
  }
});

export const createWallet: any = createAsyncThunk("wallet/createWallet", async (username: any) => {
  try {
    const response = createWalletApi(username);
    console.log({ response });
    const createdWallet = await response;
    console.log({ createdWallet });
    return response;
  } catch (error) {
    return error;
  }
})

export const deleteWallet: any = createAsyncThunk(
  "wallet/deleteWallet",
  async (username: any) => {
    try {
      const response = deleteWalletApi(username);
      console.log(response);
      const deletedWallet = await response;
      console.log(deletedWallet);
      return deletedWallet;
    } catch (error) {
      return error;
    }
  }
);

export const updateInvoice: any = createAsyncThunk(
  "chat/updateInvoice",
  async (invoice: any) => {
    try {
      const response = updateInvoiceApi(invoice);
      const modifiedEvent = await response;
      return modifiedEvent;
    } catch (error) {
      return error;
    }
  }
);



import React from "react";
import { Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import WidgetData from "../../components/Common/Widgets";
import { shallowEqual, useSelector } from "react-redux";
import { getLoggedIn } from "../../helpers/myAuth/myAuth";
import useTitle from "../../hooks/use-title";
import Orders from "./Orders";

const Redux = () => {
  useTitle("Redux");
  const data = useSelector((state => state), shallowEqual);
  console.log(data, getLoggedIn());
  // console.log( getLoggedIn());
  const { login, profile } = data
  return (
    // <React.Fragment>
    <div className="page-content" style={{ backgroundColor: "white", height: "100%" }}>
      <Container fluid>
        <h4>REDUX</h4>
        {/*Render Breadcrumbs*/}
        {/*<Breadcrumbs title="FitChain" breadcrumbItem="Home" />*/}
        <Row>
          <Col xl={1} />
          <Col xl={10}>
            <Row> <pre>
            LOGIN: {JSON.stringify(login , null, 1)}
              <br/> <br/>
            PROFILE:   {JSON.stringify( profile , null, 1)}
            </pre>
            </Row>
            <Row> <pre>
           DATA: {JSON.stringify( data , null, 1)}
            </pre>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
    // </React.Fragment>

  );
};

export default Redux;

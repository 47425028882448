import React, { useEffect, useState } from "react";

//import Breadcrumbs
import { Button, Card, CardBody, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import useTitle from "../../hooks/use-title";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import WalletPrompt from "../../components/Dashboard/WalletPrompt";
import WalletWidget from "../../components/Wallet/Widget/WalletWidget";
import TokensCard from "../../components/Wallet/BuyTokens/TokensCard";
import { getWallet as onGetWallet } from "../../slices/Wallet/thunk";

const WalletPage = () => {
  useTitle("Wallet");
  // const [isLoading, setIsLoading] = useState(false);

  const { profile } = useSelector((state => state), shallowEqual);
  const { wallet } = useSelector((state => state), shallowEqual);
  const profileData = profile && profile.success;

  const dispatch = useDispatch();

  const reloadWalletData = ()=>{
    if (profileData.name) {
      // getWalletBalance(profileData.name)
      dispatch(onGetWallet(profileData.name));
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}

          {profile && profile.success &&
            // profile.success.role === "buyer" &&
            <>

              <Row>
                <WalletPrompt />

                {wallet && wallet.last_topup && wallet.id &&
                  <>
                    <WalletWidget />

                    <Col sm={6}>
                      <TokensCard userType={profile.success.role || "user"}
                                  reloadWallet={reloadWalletData} />
                    </Col>
                  </>
                }
              </Row>
            </>}

          {/*{profile && profile.success && profile.success.role==='buyer' && <ContentUser />}*/}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default WalletPage;

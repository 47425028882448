import React from "react";
import { Alert, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import illustrator from "../../assets/images/illustrator/2.png";

const PermissionPrompt = ({metricsShared}) => {
  // console.log({ metricsShared });
  const hasAllowedPermissionForMetrics = metricsShared.length > 1

  const colorInfo = hasAllowedPermissionForMetrics ? "success" : "warning";

  return (
    <div>
      <Card>
        <CardBody>
          <Alert
            color={colorInfo}
            className="border-0 d-flex align-items-center"
          >
            <i className={`uil uil-exclamation-triangle font-size-16 text-${colorInfo} me-2`}></i>
            <div className="flex-grow-1 text-truncate">
              {!hasAllowedPermissionForMetrics && "You must set the initial permissions."}
              {hasAllowedPermissionForMetrics && "Permissions are initialized."}
            </div>
            <div className="flex-shrink-0">
              <Link to="/permissions" className="text-reset text-decoration-underline"><b>Permissions</b></Link>
            </div>
          </Alert>

          <div className="row align-items-center">
            <div className="col-sm-7">
              <p className="font-size-18">
                {!hasAllowedPermissionForMetrics ? "Set " : "Update "}
                the Permissions you want to
                <span className="fw-semibold"> Share </span>
                for proper use of the platform <i className="mdi mdi-arrow-right"></i></p>
              <div className="mt-4">
                <Link to="/permissions" className="btn btn-primary">
                  {!hasAllowedPermissionForMetrics ? "Set " : "Update "}
                  Permissions!</Link>
              </div>
            </div>
            <div className="col-sm-5">
              <img src={illustrator} className="img-fluid" alt="" />
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default PermissionPrompt;

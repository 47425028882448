import React, { useEffect, useState } from "react";
import Breadcrumbs from "../Common/Breadcrumb";
import { defaultTitle } from "../../constants/defaultValues";
import { Col, Row } from "reactstrap";
import WidgetUserInfo from "./WidgetUserInfo";
import PermissionPrompt from "./PermissionPrompt";
import StravaPrompt from "./StravaPrompt";
import WalletPrompt from "./WalletPrompt";
import WalletWidget from "../Wallet/Widget/WalletWidget";
import WidgetData from "../Common/Widgets";
import WidgetDonutPie from "../UI/WidgetDonutPie";
import { shallowEqual, useSelector } from "react-redux";
import { getSelectedMetrics } from "../../api/api";
import { findIndex } from "lodash";

const ContentUser = () => {
  const [metricsShared, setMetricsShared] = useState([]);

  const { wallet } = useSelector((state => state), shallowEqual);
  const { profile } = useSelector((state => state), shallowEqual);
  const profileData = profile && profile.success;

  useEffect(() => {
    getSelectedMetrics(profileData.name).then(async res => {
      const { metrics: selectedMetrics } = await res;
      let metrics = [{ label: "TOTAL", value: 0 }];
      for (const selectedMetric of selectedMetrics) {
        metrics[0].value++;
        for (const domain of selectedMetric.domains) {
          const domainExistsIndex = metrics.findIndex(m => m.label === domain);
          if (domainExistsIndex < 0) {
            metrics.push({ label: domain, value: 1 });
          } else {
            metrics[domainExistsIndex].value++;
          }
        }
      }
      setMetricsShared(metrics);
    }).catch(e => {
      console.log(e);
    });
    // console.log(selectedMetrics);
  }, []);
  return (
    <>
      <Breadcrumbs title={defaultTitle} breadcrumbItem="User Analytics" />
      <Row>
        <WidgetUserInfo />
      </Row>

      <Row>
        <Col lg={6}>
          <PermissionPrompt metricsShared={metricsShared}/>
        </Col>
        <Col lg={6}>
          <Row>
            <StravaPrompt />
          </Row>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <WalletPrompt />
        </Col>
        <Col md={6}>
          <WidgetDonutPie title={"Metrics Shared"}
                          labels={metricsShared.map(m => m.label)}
                          series={metricsShared.map(m => m.value)}
          />
        </Col>
      </Row>

      <Row>
        <WalletWidget />
        {/*{wallet && wallet.last_topup && wallet.id &&*/}
        {/*  <WidgetData />*/}
        {/*}*/}
      </Row>


      <Row>
        <Col sm={6}>
          {/*<EarningReports />*/}
        </Col>

        <Col sm={6}>
          {/*<SalesAnalytics />*/}
          {/*<Orders />*/}
        </Col>
      </Row>
    </>
  );
};

export default ContentUser;

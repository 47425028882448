import React from "react";
import { Col, Input, Label, Row } from "reactstrap";

const MetricInputRow = ({ activity, onClickCheckbox, onClickSwitch, onUpdateDate }) => {
  // console.log({activity});
  return (
    <div>

      {activity && <Row className="form-check d-flex">
        <Col sm={3}>
          <Input className="form-check-input" type="checkbox" id={`formCheck_` + activity.title}
                 checked={activity.isChecked}
                 onChange={onClickCheckbox.bind(this, activity.title)}
          />
          <Label className="form-check-label" htmlFor={"formCheck_" + activity.title}>
            {activity.title}
          </Label>
        </Col>

        <Col sm={9} className="form-check form-switch form-switch mb-2">
          {onClickSwitch && <Row>
            <Col md={4} className={"d-flex align-self-center"}>
              <Input className="form-check-input mx-2" type="checkbox"
                     id={`flexSwitchCheckChecked_` + activity.title}
                     onChange={onClickSwitch.bind(this, activity.title)}
                     disabled={!activity.isChecked}
                     checked={activity.isTimeSpecified} />
              <Label className="form-check-label" htmlFor={"flexSwitchCheckChecked_" + activity.title}>
                {activity.isTimeSpecified ? "Specify Timeline" : "Not Specified Timeline"}</Label>
            </Col>
            {activity.isTimeSpecified && <Col md={8} className={"d-flex"}>
              <Label htmlFor="fromDate" className="col-md-2 col-form-label">From</Label>
              <Input className="form-control" type="date" value={activity.fromDate} id="fromDate"
                     name={activity.title}
                     onChange={onUpdateDate}
              />
              <Label htmlFor="toDate" className="col-md-2 col-form-label mx-2">To</Label>
              <Input className="form-control" type="date" value={activity.toDate} id="toDate"
                     name={activity.title}
                     onChange={onUpdateDate}
              />
            </Col>}
          </Row>}
        </Col>
      </Row>}

    </div>
  );
};

export default MetricInputRow;

import axios from "axios";
import config from "../config";
// import accessToken from "./jwt-token-access/accessToken";

//pass new generated access token here
const getToken =  () => {
  const authUserObj = localStorage.getItem("authUser");
  const authUserData = authUserObj && JSON.parse(authUserObj);
  if(!(authUserData && authUserData.access_token)) // authUserData.token
    return ""
  // const token = authUserData.token.split("|")[1];
  const token = authUserData.access_token;
  return token;

};

//apply base url for axios
const API_URL = "";

const axiosApi = axios.create({
  baseURL: API_URL
});

// axiosApi.defaults.headers.common["Authorization"] = token;

// content type
axiosApi.defaults.headers.post["Content-Type"] = "application/json";

axiosApi.interceptors.request.use(
  config => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  err => Promise.reject(err)
);

// intercepting to capture errors
axiosApi.interceptors.response.use(function(response) {
  return response.data ? response.data : response;
}, async function(error) {
  // console.log("axiosApi.interceptors:",{error});
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  let message;
  switch (error.response.status) {
    case 500:
      message = "Internal Server Error";
      break;
    case 401:
      message = "Invalid credentials";
      break;
    case 404:
      message = "Sorry! the data you are looking for could not be found";
      break;
    case 422:
      message = (error.response && error.response.data && error.response.data.message || "422");
      break;
    default:
      message = error.message || error;
  }
  const originalRequest = error.config;
  // 401 or 403
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;

    // const access_token = await refreshAccessToken();
    // axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
    // return axiosApi(originalRequest);

    try {
      const authUserObj = localStorage.getItem("authUser");
      const authUserData = authUserObj && JSON.parse(authUserObj);
      const refreshToken = authUserData.refresh_token;
      const username = authUserData.username;

      // Make a request to your auth server to refresh the token.
      const response = await axios.post(config.fitchain.API_HOST + '/api/' + username + "/refreshToken",
        {},
        {
          headers: { Authorization: `Bearer ${refreshToken}` }
        });
      // console.log({response});
      authUserData.access_token= response.data.access_token
      authUserData.access_token_lifetime= response.data.access_token_lifetime

      // const { accessToken, refreshToken: newRefreshToken } = response.data;
      // Store the new access and refresh tokens.
      localStorage.setItem('authUser', JSON.stringify(authUserData));
      // Update the authorization header with the new access token.
      // axiosApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      return axiosApi(originalRequest); // Retry the original request with the new access token.
    } catch (refreshError) {
      // Handle refresh token errors by clearing stored tokens and redirecting to the login page.
      console.error('Token refresh failed:', refreshError);
      localStorage.removeItem('authUser');
      window.location.href = '/login';
      return Promise.reject(refreshError);
    }
  }

  return Promise.reject(message);
});


class ApiCore {
  /**
   * Fetches data from given url
   */
  get = (url: string, params?: {}) => {
    return axiosApi.get(url, params);
  };

  /**
   * post given data to url
   */
  create = (url: string, data: {}) => {
    return axiosApi.post(url, data);
  };

  /**
   * Updates data
   */
  update = (url: string, data: {}) => {
    return axiosApi.put(url, data);
  };

  /**
   * Delete
   */
  delete = (url: string, data?: {}) => {
    return axiosApi.delete(url, { ...data });
  };
};

export { ApiCore };

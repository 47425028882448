import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import axios from "axios";
import { updateProfile } from "../../api/api";
import { getCurrentUser } from "../../helpers/myAuth/myAuth";

const REACT_APP_CLIENT_ID = 131354;
const REACT_APP_CLIENT_SECRET = "d8024e7318303d0c6030f20b5ec9d9588d041b97";

const StravaParse = () => {
  const stravaAuthToken = cleanUpAuthToken(location.search);
  // const stravaAuthToken = "asdasdf";

  const [stravaUserData, setStravaUserData] = useState({});
  const [activities, setActivities] = useState({});

  const accessToken = stravaUserData?.access_token;
  const userID = stravaUserData && stravaUserData.athlete && stravaUserData.athlete.id;

  const updateStravaData = (stravaData) => {
    const username = getCurrentUser().username
    console.log({username,stravaData});
    updateProfile(username, {
      "strava_access": stravaData.access_token,
      "strava_refresh": stravaData.refresh_token,
      "strava_expire": stravaData.expires_at
    }).then().catch(e => {
      console.log({ e });
    });
  };
  useEffect(() => {
    const request = async () => {
      const data = await testAuthGetter(stravaAuthToken);
      // const data = {}
      setStravaUserData(data);
      if (data)
        updateStravaData(data);
    };
    request();
  }, []);

  const handleActivities = async () => {
    const data = await getUserData(userID, accessToken);
    // const data = {}
    setActivities(data);

  };
  return (

    <div className="page-content">
      <Container fluid>
        <Row>
          <Col>
            <Row> <pre>
            {JSON.stringify(stravaUserData, null, 1)}
            </pre>
            </Row>
            <Row> <pre>
              {JSON.stringify({ "accessToken": accessToken, userID })}
            </pre>
            </Row>
          </Col>
          <Row>
            <button onClick={handleActivities}>fetch activities</button>
          </Row>
        </Row>
      </Container>
    </div>
  );
};

export default StravaParse;

const getUserData = async (userID, accessToken) => {
  try {
    const response = await axios.get(
      `https://www.strava.com/api/v3/athletes/${userID}/stats`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

const testAuthGetter = async (authTok) => {
  try {
    const response = await axios.post(
      `https://www.strava.com/api/v3/oauth/token?client_id=${REACT_APP_CLIENT_ID}&client_secret=${REACT_APP_CLIENT_SECRET}&code=${authTok}&grant_type=authorization_code`
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const cleanUpAuthToken = (str) => {
  return str.split("&")[1].slice(5);
};

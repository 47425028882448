import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getProfile } from "../../api/api";
import { profileSuccess } from "../../slices/Auth/Profile/reducer";

const Authmiddleware = (props: any) => {
    if (!localStorage.getItem("authUser") && !props.disabled) {
      return (
        <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
      );
    }
    if (props.roles) {
      const authUserObj = localStorage.getItem("authUser");
      const authUserData = authUserObj && JSON.parse(authUserObj);
      const role = authUserData && authUserData.role;
      if (props.roles && !props.roles.includes(role)){
        // return to not authorized page todo
        return (
          <Navigate to={{ pathname: "/", state: { from: props.location } }} />
        );
      }
    }

    if (!localStorage.getItem("authUser") && !props.disabled) {
      return (
        <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
      );
    }

    const dispatch = useDispatch();

    useEffect(() => {
      const authUserObj = localStorage.getItem("authUser");
      const authUserData = authUserObj && JSON.parse(authUserObj);
      const username = authUserData && authUserData.username;
      if ((username)) {
        getProfile(username).then(d => {
          console.log(d);
          dispatch(profileSuccess(d));
        }).catch(e => {
          console.log(e);
        });
      }
    }, []);

    return (<React.Fragment>
      {props.children}
    </React.Fragment>);
  }
;

export default Authmiddleware;

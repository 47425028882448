import React, { useEffect, useState } from "react";
import { activityTypes } from "../../constants/defaultValues";
import { Button, Card, Col, Input, Label, Row, Spinner } from "reactstrap";
import { getMetrics, getSelectedMetrics } from "../../api/api";
import { shallowEqual, useSelector } from "react-redux";
import MetricInputRow from "./MetricInputRow";

const Permissions = (props) => {
  const [metricsProcessed, setMetricsProcessed] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [error, setError] = useState("");

  const { isLoading: isSaving = false, onSave } = props;
  console.log(metricsProcessed);
  const { profile } = useSelector((state => state), shallowEqual);
  const profileData = profile && profile.success;

  const isSelectedAll = metricsProcessed.findIndex(metric => !metric.isChecked === true) < 0;

  useEffect(() => {
    // fetch Activity Types && personal permissions
    if (profileData.name) {
      setIsLoadingData(true);
      setError("");
      getMetrics().then(async res => {
        console.log(res);
        // const data = res.count;
        // console.log(data);
        const metrics = res && res.metrics;
        console.log(metrics);
        const { metrics: selectedMetrics } = await getSelectedMetrics(profileData.name);
        console.log(selectedMetrics);
        const metricsData = [...metrics.map(activity => {
          const selectedMetricsIndex = selectedMetrics.findIndex(sm => activity.id === sm.id);
          const isSelected = (selectedMetricsIndex > -1);
          let selectedMetric;
          if (isSelected)
            selectedMetric = selectedMetrics[selectedMetricsIndex];

          return {
            ...activity,
            title: activity.description,
            isChecked: isSelected,
            isTimeSpecified: isSelected && (selectedMetric.from || selectedMetric.to),
            fromDate: isSelected ? selectedMetric.from && selectedMetric.from.split(" ", 1) : null, //"2024-08-19",
            toDate: isSelected ? selectedMetric.to && selectedMetric.to.split(" ", 1) : null //"2024-08-19"
          };
        })];
        setMetricsProcessed(metricsData);
      }).catch(error => {
        console.log({ error });
        setError("Could not load data!");
      })
        .finally(() => {
          setIsLoadingData(false);
        });
    }
  }, [profileData.name]);
  
  const onClickSelectAll = () => {
    setMetricsProcessed(prevMetrics => {
      return prevMetrics.map(metric => {
        return { ...metric, isChecked: !isSelectedAll };
      });
    });
  };
  const onClickCheckbox = (title) => {
    const activityIndex = metricsProcessed.findIndex(activity => activity.title === title);
    if (activityIndex < 0) return;

    const activityToUpdate = { ...metricsProcessed[activityIndex] };
    const activityUpdated = {
      ...activityToUpdate,
      isChecked: !activityToUpdate.isChecked,
      isTimeSpecified: false,
      fromDate: !activityToUpdate.isTimeSpecified ? activityToUpdate.fromDate : undefined,
      toDate: !activityToUpdate.isTimeSpecified ? activityToUpdate.toDate : undefined
    };
    const activityTypesCopy = [...metricsProcessed];
    activityTypesCopy.splice(activityIndex, 1, activityUpdated);
    setMetricsProcessed(activityTypesCopy);
  };
  const onClickSwitch = (title) => {
    const activityIndex = metricsProcessed.findIndex(activity => activity.title === title);
    if (activityIndex < 0) return;

    const activityToUpdate = { ...metricsProcessed[activityIndex] };
    const activityUpdated = {
      ...activityToUpdate,
      isTimeSpecified: !activityToUpdate.isTimeSpecified,
      fromDate: !activityToUpdate.isTimeSpecified ? activityToUpdate.fromDate : undefined,
      toDate: !activityToUpdate.isTimeSpecified ? activityToUpdate.toDate : undefined
    };
    const activityTypesCopy = [...metricsProcessed];
    activityTypesCopy.splice(activityIndex, 1, activityUpdated);
    setMetricsProcessed(activityTypesCopy);
  };

  const onUpdateDate = (e) => {
    const { name: title, value, id } = e.target;
    const activityIndex = metricsProcessed.findIndex(activity => activity.title === title);
    if (activityIndex < 0) return;

    const activityToUpdate = { ...metricsProcessed[activityIndex] };
    const activityUpdated = {
      ...activityToUpdate,
      [id]: value
    };
    const activityTypesCopy = [...metricsProcessed];
    activityTypesCopy.splice(activityIndex, 1, activityUpdated);
    setMetricsProcessed(activityTypesCopy);
  };

  const saveMetricsHandler = () => {
    console.log({ activityTypesProcessed: metricsProcessed });
    const metricsData = metricsProcessed
      // .filter(m => m.isChecked)
      .map(m => {
        return {
          name: m.name,
          authorize: m.isChecked,
          from: (m.isTimeSpecified && m.fromDate) || null,
          to: (m.isTimeSpecified && m.toDate) || null
        };
      });
    onSave(profileData.name, metricsData);
  };

  return (
    <Card style={{ padding: "2rem" }}>

      {isLoadingData && <div className={"d-flex justify-content-center align-items-center"}>
        <Spinner size="lg" color={"primary"}>
          Loading...
        </Spinner>
      </div>
      }
      {!isLoadingData && error && < div className={"d-flex justify-content-center align-items-center"}>
        {error}
      </div>
      }
      {!isLoadingData && <>

        {/*<h5 className="font-size-13 text-uppercase text-muted mb-4"><i*/}
        {/*  className="mdi mdi-chevron-right text-primary me-1"></i> Data Permissions</h5>*/}

        <MetricInputRow // Select all
          activity={{ id: 9999, title: "Select All", isChecked: isSelectedAll }}
          onClickCheckbox={onClickSelectAll}
        />
        <br />
        {metricsProcessed.map((activity, i) =>
          <MetricInputRow key={i}
                          activity={activity}
                          onClickCheckbox={onClickCheckbox}
                          onClickSwitch={onClickSwitch}
                          onUpdateDate={onUpdateDate}
          />
        )
        }

        {metricsProcessed && metricsProcessed.length > 0 && <div>
          <Button onClick={saveMetricsHandler}
                  className={"px-3 mt-3"}
                  color={"primary"}
                  disabled={isSaving}
          >
           <span>
             Save
           </span>
            <span style={{ visibility: isSaving ? "visible" : "hidden", marginLeft: "4px" }}>

             <Spinner size="sm">
              Loading...
            </Spinner>
           </span>
          </Button>
        </div>}
      </>}
    </Card>
  );
};

export default Permissions;

import React, { useEffect, useState } from "react";
import { Alert, Card, CardBody } from "reactstrap";
import logo from "../../assets/images/stravaLogo.webp";
import { shallowEqual, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getStravaInfo } from "../../api/api";

const REACT_APP_CLIENT_ID = 131354;
const REACT_APP_CLIENT_SECRET = "d8024e7318303d0c6030f20b5ec9d9588d041b97";
const redirectUrl = `${process.env.REACT_APP_HOST}/redirect_strava`;
const scope = "read";
const StravaPrompt = () => {

  const { profile } = useSelector((state => state), shallowEqual);
  const profileData = profile && profile.success;

  const [stravaInfo, setStravaInfo] = useState({});
  // const hasStravaAccess = profileData && profileData.strava_access;
  const hasStravaAccess = stravaInfo && stravaInfo.status;
  const stravaUpdated = stravaInfo && stravaInfo.last_sync && stravaInfo.last_sync.split(" ",1);

  console.log({ profileData });
  const colorInfo = hasStravaAccess ? "success" : "warning";

  useEffect(() => {
    getStravaInfo(profileData.name).then(res => {
      setStravaInfo(res);
    }).catch(e => {
      console.log(e);
    });
  }, []);

  // const createStravaAccessHandler = () => {
  //   window.location = ` https://userstats.fitchain.pikei.xyz/import`;
  //
  //   // window.location = `http://www.strava.com/oauth/authorize?`
  //   //   + `client_id=${REACT_APP_CLIENT_ID}`
  //   //   + `&response_type=code`
  //   //   + `&redirect_uri=${redirectUrl}/exchange_token&approval_prompt=force`
  //   //   + `&scope=${scope}`;
  // };
  // const synchronizeDataHandler = () => {
  //   if (profileData.name) {
  //     // deleteWalletAction(profileData.name)
  //     // dispatch(onDeleteWallet(profileData.name));
  //   }
  // };

  return (
    <div>
      <Card>
        <CardBody style={{ minHeight: "240px" }}>
          <Alert
            color={colorInfo}
            className="border-0 d-flex align-items-center"
          >
            <i className={`uil uil-exclamation-triangle font-size-16 text-${colorInfo} me-2`}></i>
            <div className="flex-grow-1 text-truncate">
              {hasStravaAccess && "You are connected with Strava."}
              {!hasStravaAccess && "You must initialize Strava connection."}
            </div>
            {/*<div className="flex-shrink-0">*/}
            {/*  <Link to="/permissions" className="text-reset text-decoration-underline"><b>Permissions</b></Link>*/}
            {/*</div>*/}
          </Alert>

          <div className="row align-items-center">
            <div className="col-sm-7">
              <p className="font-size-16">

                {!hasStravaAccess && <>
                  Click below to redirect to <span className="fw-semibold"> run247  </span> for Strava activation
                </>}

                {hasStravaAccess && <>
                  Last Update: {stravaUpdated}
                  <br />
                  <span className={"font-size-12"}>Click below to redirect to <span
                    className="fw-semibold"> run247  </span> for Strava activation
                  </span>
                </>}
              </p>
              <div className="mt-4">

                {/*<button to="#" className="btn btn-primary">Synchronize Data</button>*/}

                {!hasStravaAccess &&
                  <Link to="https://stats.run247.gr/" target={"_blank"} className="btn btn-success"
                        // onClick={createStravaAccessHandler}
                    // disabled={wallet.isLoading}
                  > Connect with Strava </Link>}
                {hasStravaAccess &&
                  <Link to="https://stats.run247.gr/" target={"_blank"} className="btn btn-primary"
                        // onClick={synchronizeDataHandler}
                    // disabled={wallet.isLoading}
                  >Synchronize Data</Link>}

              </div>
            </div>
            <div className="col-sm-5 text-center" style={{ maxHeight: "150px" }}>
              <i className="fab fa-strava fa-8x"></i>
              {/*<img src={logo} className="img-fluid" alt="" style={{maxHeight:"140px"}} />*/}
            </div>

          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default StravaPrompt;

import React from "react";
import { Col, Container, Row } from "reactstrap";
import { shallowEqual, useSelector } from "react-redux";
import { getLoggedIn } from "../../helpers/myAuth/myAuth";
import useTitle from "../../hooks/use-title";
import Orders from "./Orders";
import WidgetPublicInfo from "../../components/Dashboard/WidgetPublicInfo";
import logikaX from "../../assets/images/fitChain/logikaX-logo.png";
import logoNGI from "../../assets/images/fitChain/logo-NGI.png";
import NeuronAlLogo from "../../assets/images/fitChain/NeuronAI-logo.svg";

const Home = () => {
  useTitle("Home");
  const data = useSelector((state => state.login), shallowEqual);
  console.log(data, getLoggedIn());
  return (
    <React.Fragment>

      <div className="page-content"
           style={{
             backgroundColor: "white", height: "100%",
             paddingLeft: 0,
             paddingRight: 0,
             paddingBottom: 0,
           }}>
        {/*rgb(86 196 231)*/}
        {/*rgb(161 90 247)*/}
        {/*rgb(199 37 255)*/}
        <div style={{height:"100px",
          // backgroundColor:"rgb(161 90 247)",
          // background: rgb(86,196,231);
          background: "linear-gradient(90deg, rgba(86,196,231,1) 0%, rgba(142,117,243,1) 58%, rgba(161,90,247,1) 84%, rgba(199,37,255,1) 100%)",
          position:"relative", top: -20}}
        ></div>

        <Container fluid>
          {/*Render Breadcrumbs*/}
          {/*<Breadcrumbs title="FitChain" breadcrumbItem="Home" />*/}
          <Row>
            {/*<Col xl={1} />*/}
            <Col lg={8} style={{ position:"relative", top:"-50px"}}>
              <Orders />
            </Col>

            <Col lg={4} style={{ position:"relative", top:"-50px"}}>
              <WidgetPublicInfo />
            </Col>

            {/*<Col lg={4}>*/}
            {/*  <Widgets2 />*/}
            {/*</Col>*/}

          </Row>
          <Row style={{ padding: "10px" }} className={"align-items-center"}>
            <Col sm={4}>
              <div className="d-flex justify-content-center align-items-center">
                <img src={NeuronAlLogo} alt="" className={"img-fluid"} style={{ padding: "25px", maxHeight: 80 }} />
              </div>
            </Col>

            <Col sm={4}>
              <div className="d-flex justify-content-center align-items-center">
                <img src={logoNGI} alt="" className={"img-fluid"} style={{ padding: "25px", maxHeight: 120 }} />
              </div>
            </Col>

            <Col sm={4}>
              <div className="d-flex justify-content-center align-items-center">
                <img src={logikaX} alt="" className={"img-fluid"} style={{ padding: "25px", maxHeight: 150 }} />
              </div>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>

  );
};

export default Home;

import React, { useState } from "react";

//import Breadcrumbs
import { Col, Container, Row } from "reactstrap";
import useTitle from "../../hooks/use-title";
import Strava from "../../components/Strava/Strava";
import { shallowEqual, useSelector } from "react-redux";
import ContentUser from "../../components/Dashboard/ContentUser";
import ContentBuyer from "../../components/Dashboard/ContentBuyer";

const DashboardPage = () => {
  useTitle("User");
  const [needPermissions, setNeedPermissions] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const { profile } = useSelector((state => state), shallowEqual);
  const { wallet } = useSelector((state => state), shallowEqual);

  const saveHandler = () => {
    setIsLoading(true);
    setTimeout(() => {
      setNeedPermissions(false);
      setIsLoading(false);
    }, 2000);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}

          {profile && profile.success && profile.success.role === "user" && <ContentUser />}

          {profile && profile.success && profile.success.role === "buyer" && <ContentBuyer />}

          {/*<Row>*/}
          {/*  <Col sm={6}>*/}
          {/*    <Row> <pre>*/}
          {/*  {JSON.stringify(profile, null, 1)}*/}
          {/*  </pre>*/}
          {/*    </Row>*/}

          {/*  </Col>*/}
          {/*  <Col sm={6}><Row>*/}
          {/*    <pre>*/}
          {/*  {JSON.stringify(wallet, null, 1)}*/}
          {/*  </pre>*/}
          {/*    <Strava /> </Row>*/}
          {/*  </Col>*/}
          {/*</Row>*/}

        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardPage;

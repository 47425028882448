import React, { useEffect, useState } from "react";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card, Container, Row } from "reactstrap";
import useTitle from "../../hooks/use-title";
import Permissions from "../../components/Permissions/Permissions";
import { defaultTitle } from "../../constants/defaultValues";
import BuyMetrics from "../../components/Permissions/BuyMetrics";
import CalculationModal from "../../components/Permissions/CalculationModal";
import PurchasesHistory from "../../components/Permissions/PurchasesHistory";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { calculateMetricsData, orderSelectedData } from "../../api/api";
import { getWallet as onGetWallet } from "../../slices/Wallet/thunk";

const MarketPage = () => {
  useTitle("Permissions");
  // const [profile, setProfileData] = useState({});
  // const [needPermissions, setNeedPermissions] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDataToPurchase, setSelectedDataToPurchase] = useState({});
  const [selectedDataToPurchaseInfo, setSelectedDataToPurchaseInfo] = useState({});

  const { profile } = useSelector((state => state), shallowEqual);
  const profileData = profile && profile.success;
  const dispatch = useDispatch();

  useEffect(() => {
    // getProfile().then(d => {
    //   console.log(d);
    //   setProfileData(d);
    // }).catch(e => {
    //   console.log(e);
    // });
  }, []);

  const calculateHandler = async (data) => {

    if (profileData.name) {
      dispatch(onGetWallet(profileData.name));
      const calculatedData = await calculateMetricsData(profileData.name, data).catch(e => {
      });
      // console.log({calculatedData});
      setSelectedDataToPurchase(data);
      setSelectedDataToPurchaseInfo(calculatedData ?? {});
    }

    setIsLoading(true);
    setTimeout(() => {
      // setNeedPermissions(false)
      setIsLoading(false);
      setIsModalOpen(true);
    }, 2000);
  };

  const completeHandler = async () => {
    const calculatedData = await orderSelectedData(profileData.name, selectedDataToPurchase).catch(e => {
    });
    setIsModalOpen(false);
    dispatch(onGetWallet(profileData.name));
  };
  return (
    <React.Fragment>
      <div className="page-content">
      <Container fluid>
        {/* Render Breadcrumbs */}
        <Breadcrumbs title={defaultTitle} breadcrumbItem="Market" />

        <Card
              style={{ padding: "2rem" }}
              // style={{ margin: "0" }}
        >

          <Row>
            {/*<h5 className="m-1 mb-sm-0"> Permissions </h5>*/}
            <BuyMetrics isLoading={isLoading} onCalculate={calculateHandler} />

            <CalculationModal isOpen={isModalOpen}
                              toggleModal={() => setIsModalOpen(false)}
                              calculatedData={selectedDataToPurchaseInfo}
                              onComplete={completeHandler}
            />

            {/*<PurchasesHistory />*/}

          </Row>
        </Card>
        {/*<Row> <pre>*/}
        {/*  {JSON.stringify(profile, null, 1)}*/}
        {/*  </pre>*/}
        {/*</Row>*/}


      </Container>
      </div>
    </React.Fragment>
  );
};

export default MarketPage;

import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";
import BuyTokensModal from "./BuyTokensModal";
import MonetizeTokensModal from "./MonetizeTokensModal";

const TokensCard = ({ userType, reloadWallet }) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  console.log({ userType });
  return (
    <>
      <Card>
        <CardBody style={{ minHeight: "200px" }}>
          <div style={{ minHeight: "160px" }}
               className="d-flex flex-column justify-content-between">
            <div>
              <h6 className="font-size-xs text-uppercase">
                {userType === "buyer" ? "Top up" : "Monetize"}
              </h6>
              {/*<h4 className="mt-4 font-weight-bold mb-2 d-flex align-items-center">*/}
              {/*  price*/}
              {/*</h4>*/}
              <div className="text-muted">
                {userType === "buyer" ? "Topup your wallet with virtual tokens" : "Monetize your virtual tokens"}
              </div>

            </div>
            {/*<div className={'d-flex justify-content-center'}>*/}
            <button className="btn btn-primary align-self-center w-50"
                    onClick={toggleModal}
              // disabled={wallet.isLoading}
            >
              {userType === "buyer" ? "Get Tokens " : "Monetize tokens"}
            </button>
            {/*</div>*/}
          </div>
        </CardBody>
      </Card>

      {userType === "buyer" && <BuyTokensModal isModalOpen={isModalOpen}
                                               toggleModal={toggleModal}
                                               reloadWallet={reloadWallet}
      />}
      {userType === "user" && <MonetizeTokensModal isModalOpen={isModalOpen}
                                                   toggleModal={toggleModal}
                                                   reloadWallet={reloadWallet}
      />}
    </>
  );
};

export default TokensCard;

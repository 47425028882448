import React from "react";
import ReactApexChart from "react-apexcharts";

const DonutPie = (props : any) => {
  const chartheight = props.page ? "215" : "245";

    return (
        <React.Fragment>
            <ReactApexChart
                options={props.options}
                series={props.series}
                type="donut"
                height={chartheight}
                className="apex-charts"
            />
        </React.Fragment>
    );
};

export default DonutPie;

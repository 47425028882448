import React from "react";
import { Col ,Row} from "reactstrap";
import WidgetGraph from "../UI/WidgetGraph";
import WidgetDonutPie from "../UI/WidgetDonutPie";

const WidgetPublicInfo = () => {
  return (
    <div>
      <Row >
        <Col lg={12} md={6} >
          <WidgetGraph />
        </Col>
        <Col lg={12} md={6}>
          <WidgetDonutPie />
        </Col>
      </Row>
    </div>
  );
};

export default WidgetPublicInfo;

import React, { useEffect, useMemo, useState } from "react";
import TableContainer from "../Common/TableContainer";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { calculateMetricsData, getOrdersList } from "../../api/api";
import { shallowEqual, useSelector } from "react-redux";

const PurchasesHistory = () => {
  const [tableData, setTableData] = useState([]);
  // const tableData = [...FIXED_DATA];

  const { profile } = useSelector((state => state), shallowEqual);
  const profileData = profile && profile.success;

  useEffect(() => {
    const fetchList = async () => {
      const calculatedData = await getOrdersList(profileData.name).catch(e => {
      });

      if (!calculatedData)
        return;

      const processedData = calculatedData.orders.map(o => {
        return {
          details: (o.order_data && (o.order_data.domains.map(d => {
            const from = d.from && d.from.split(" ", 1)
            const to = d.to && d.to.split(" ", 1)
            return d.domain +
              ": ["+
              (from ? " From: " + from : "") +
              (to ? " Till: " + to : "") +
              ((!from && !to)? "all time" : "")
              + "]";
          })).toString()) ?? "",
          metrics: o.result ?? 56,
          cost: o.tokens,
          date: o.date ?? "DD-MM-YYYY",
          status: o.status,
          uuid: o.uuid
        };
      });
      setTableData(processedData);
    };
    if (profileData.name) {
      fetchList();
    }
  }, [profileData.name]);

  const onDownloadData = (uuid) => {
    console.log("To Do download: " + uuid);
  };
  const columns = useMemo(
    () => [
      // {
      //   Header: "# ",
      //   Cell: () => {
      //     return <div className="form-check font-size-16">
      //       <input type="checkbox" className="form-check-input" />
      //       <label className="form-check-labe10"></label>
      //     </div>;
      //   },
      // },
      {
        Header: "# ID",
        accessor: "uuid",
        disableFilters: true,
        filterable: true
      },
      {
        Header: "	Details",
        accessor: "details",
        disableFilters: true,
        filterable: true
      },
      {
        Header: "Metrics",
        accessor: "metrics",
        disableFilters: true,
        filterable: true
      },
      {
        Header: "Tokens",
        accessor: "cost",
        disableFilters: true,
        filterable: true
      },
      {
        Header: "Date",
        accessor: "date",
        disableFilters: true,
        filterable: true
      },
      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        filterable: true,
        Cell: (invoice) => {
          console.log(invoice);
          return (
            <div
              className={
                "badge badge-soft-" +
                // invoice.row.original.badgecolor +
                "font-size-12 text-dark"
              }
            >
              {invoice.row.original.status}
            </div>
          );
        }
      }
      /*
            {
              Header: "Action",
              Cell: (cellProps) => {
                return (
                  <UncontrolledDropdown>
                    <DropdownToggle
                      className="btn btn-link text-muted py-1 font-size-16 shadow-none"
                      tag="a"
                    >
                      <i className="bx bx-dots-horizontal-rounded"></i>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem
                        onClick={() => onDownloadData(cellProps.row.original.uuid)}
                      >
                        Download
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                );
              }
            }
            */
    ],
    []
  );
  const handleUserClicks = () => {
    setIsEdit(false);
    toggle();
  };
  return (
    <div>
      <p className={"py-4"}>
        <h5>
          Purchases History
          <hr />
        </h5>
      </p>
      <TableContainer
        columns={columns}
        data={tableData}
        // isGlobalFilter={true}
        // isAddInvoiceList={true}
        // className="custom-header-css"
        handleInvoiceClick={handleUserClicks}
        customPageSize={2}
        customPageSizeOptions={[2, 5, 10, 20]}

      />
    </div>
  );
};

export default PurchasesHistory;

const FIXED_DATA = [{
  details: "name1",
  metrics: 56,
  cost: 71.2,
  date: "29-01-2022",
  status: "completed",
  uuid: "a4w5sd4-sdg56-sdf324"
}, {
  details: "name3",
  metrics: 56,
  cost: 77.2,
  date: "29-01-2022",
  status: "processing",
  uuid: "a4w5sd4-sdg56-sdf324"
}, {
  details: "name2",
  metrics: 56,
  cost: 77.2,
  date: "29-01-2022",
  status: "processing",
  uuid: "a4w5sd4-sdg56-sdf324"
}, {
  details: "name",
  metrics: 56,
  cost: 79.2,
  date: "29-01-2022",
  status: "completed",
  uuid: "a4w5sd4-sdg56-sdf324"
}];

import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

//import withRouter
import withRouter from "../../Common/withRouter";

//i18n
import { withTranslation } from "react-i18next";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";

//redux
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { createSelector } from "reselect";

const ProfileMenu = (props: any) => {

  const selectLeadData = createSelector(
    (state: any) => state.profile.success,
    (leadsChart) => leadsChart
  );
  // Inside your component
  const success = useSelector(selectLeadData);

  const username = success.name
  const role = success.role
  console.log(success);
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState<boolean>(false);

  // const [username, setusername] = useState("Admin");
  const [email, setemail] = useState<string>("admin@themesbrand.com");

  useEffect(() => {
    const getAuthUser = localStorage.getItem("authUser");
    if (getAuthUser) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(getAuthUser);
        if (obj.displayName) {
          // setusername(obj.displayName);
        } else {
          // setusername(obj.fullName);
        }
        setemail(obj.email);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(getAuthUser);
        if (obj.username) {
          // setusername(obj.username);
          setemail(obj.email);
        } else {
          // setusername(obj.name);
        }

      }
    }
  }, [success]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item user text-start d-flex align-items-center"
          id="page-header-user-dropdown"
          tag="button"
        >
          <div className="rounded-circle header-profile-user d-flex justify-content-center align-items-center"
           >
            <i className="fa-regular fa-user"></i>
          </div>
          {/*<img*/}
          {/*  className="rounded-circle header-profile-user"*/}
          {/*  src={user1}*/}
          {/*  alt="Header Avatar"*/}
          {/*/>*/}
          <span className="ms-2 d-none d-sm-block user-item-desc">
            <span className="user-name">{username}</span>
            <span className="user-sub-title">{role}</span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end pt-0" dir="left">
          <div className="p-3 bg-primary border-bottom">
            <h6 className="mb-0 text-white">{username}</h6>
            <p className="mb-0 font-size-11 text-white-50 fw-semibold">
              {email}
            </p>
          </div>

          {/*   Dashboard   */}
          <Link to="/dashboard">
            <DropdownItem>
              <i className="mdi mdi-monitor text-muted font-size-16 align-middle me-1"></i>{" "}
              <span className="align-middle">
                {props.t("Dashboard")}
            </span>
            </DropdownItem>
          </Link>

          {/*   Profile   */}
          <Link to="/profile">
            <DropdownItem>
              <i className="mdi mdi-account-circle text-muted font-size-16 align-middle me-1"></i>{" "}
              <span className="align-middle">
                {props.t("Profile")}
            </span>
            </DropdownItem>
          </Link>

          {role === 'user' &&
            <Link to="/permissions">
              <DropdownItem>
                <i className="mdi mdi-clipboard-edit-outline text-muted font-size-16 align-middle me-1"></i>{" "}
                <span className="align-middle">
              {" "}
                  {props.t("Permissions")}
            </span>
              </DropdownItem>
            </Link>
          }

          <Link to="/transactions/user">
            <DropdownItem>
              <i className="mdi mdi-calendar-check-outline text-muted font-size-16 align-middle me-1"></i>{" "}
              <span className="align-middle">
                {props.t("Transactions")}
            </span>
            </DropdownItem>
          </Link>
          {role ==='buyer' &&
            <Link to="/mypurchases">
              <DropdownItem>
                <i className="mdi mdi-shopping-outline text-muted font-size-16 align-middle me-1"></i>{" "}
                <span className="align-middle">
                {props.t("My Purchases")}
            </span>
              </DropdownItem>
            </Link>
          }
          <Link to="/wallet">
            <DropdownItem>
              <i className="mdi mdi-wallet text-muted font-size-16 align-middle me-1"></i>{" "}
              <span className="align-middle">
                 {props.t("Wallet")}
                {/*: <b>85 </b>*/}
            </span>
            </DropdownItem>
          </Link>
          <DropdownItem divider />
          <Link to="/pages-faqs">
            <DropdownItem>
              <i className="mdi mdi-lifebuoy text-muted font-size-16 align-middle me-1"></i>{" "}
              <span className="align-middle">
                {props.t("Help")}
            </span>
            </DropdownItem>
          </Link>
          {/*<Link to="/user-settings">*/}
          {/*  <DropdownItem className="d-flex align-items-center">*/}
          {/*    <i className="mdi mdi-cog-outline text-muted font-size-16 align-middle me-1"></i>{" "}*/}
          {/*    <span className="align-middle">*/}
          {/*      {props.t("Settings")}*/}
          {/*  </span>*/}
          {/*    <span className="badge badge-success-subtle ms-auto">New</span>*/}
          {/*  </DropdownItem>*/}
          {/*</Link>*/}
          <Link to="/logout">
            <DropdownItem>
              <i className="mdi mdi-logout text-muted font-size-16 align-middle me-1"></i>{" "}
              <span className="align-middle">
                {props.t("Logout")}
            </span>
            </DropdownItem>
          </Link>

        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(withRouter(ProfileMenu));

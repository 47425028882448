import { useEffect, useRef } from 'react';
import { defaultTitle } from '../constants/defaultValues';

export interface UseTitleOptions {
  restoreOnUnmount?: boolean;
}

const DEFAULT_USE_TITLE_OPTIONS: UseTitleOptions = {
  restoreOnUnmount: false
};
const useTitle = (title: string, options: UseTitleOptions = DEFAULT_USE_TITLE_OPTIONS) => {
  const prevTitleRef = useRef(document.title);

  if (document.title !== title) document.title = title + ' - ' + defaultTitle;

  useEffect(() => {
    if (options && options.restoreOnUnmount) {
      return () => {
        document.title = prevTitleRef.current;
      };
    } else {
      return () => {
        document.title = defaultTitle;
      };
    }
  }, []);
};
export default typeof document !== 'undefined' ? useTitle : (_title: string) => {
};

import React, { useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { orderTokens } from "../../../api/api";
import { shallowEqual, useSelector } from "react-redux";

const BuyTokensModal = ({ isModalOpen, toggleModal, reloadWallet }) => {
  const [inputTokensValue, setInputTokensValue] = useState(0);
  const [nestedModal, setNestedModal] = useState(false);

  const { profile } = useSelector((state => state), shallowEqual);
  const profileData = profile && profile.success;

  const addTokens = (e) => {
    console.log(e.target.value);
    const amount = e.target.value / 1;
    setInputTokensValue(prevValue => prevValue + amount);
  };
  const onChangeValue = (e) => {
    setInputTokensValue(e.target.value / 1);
  };
  const completeExchange = () => {
    if (profileData.name) {
      orderTokens(profileData.name, { tokens: inputTokensValue })
        .then(data => {
          console.log(data);
          setNestedModal(false);
          toggleModal();
          setInputTokensValue(0);
          reloadWallet()
        }).catch(e => {
        console.log(e);
      });
    }
  };
  const backExchange = () => {
    setNestedModal(false);
  };
  const cancelExchange = (e) => {
    setNestedModal(false);
    toggleModal();
    setInputTokensValue(0);
  };

  return (<>
      <Modal isOpen={isModalOpen} toggle={toggleModal} backdrop={"static"}>
        <ModalHeader toggle={cancelExchange}>Virtual Token Exchange </ModalHeader>
        <ModalBody style={{ minHeight: "200px" }}
                   className={"d-flex flex-column justify-content-evenly"}
        >
          This is a virtual environment for you to acquire virtual tokens

          <InputGroup>
            <InputGroupText className={"p-0"}>
              <button className={"btn btn-dark"} value={5} onClick={addTokens}> +5</button>
            </InputGroupText>

            <InputGroupText className={"py-0 px-1"}>
              <button className={"btn btn-dark"} value={10} onClick={addTokens}> +10</button>
            </InputGroupText>

            <InputGroupText className={"py-0 px-1"}>
              <button className={"btn btn-dark"} value={25} onClick={addTokens}> +25</button>
            </InputGroupText>

            <InputGroupText className={"p-0 px-1"}>
              <button className={"btn btn-dark"} value={50} onClick={addTokens}> +50</button>
            </InputGroupText>

            <Input
              id="exampleNumber"
              name="number"
              placeholder="number placeholder"
              type="number"
              value={inputTokensValue}
              onChange={onChangeValue}
              min={0}
              style={{ minWidth: "50px" }}
            />
            <InputGroupText>
              tokens
            </InputGroupText>

          </InputGroup>

        </ModalBody>
        <ModalFooter>
          <Button color="success"
                  onClick={() => setNestedModal(true)}
                  disabled={inputTokensValue <= 0}
          >
            Proceed
          </Button>{" "}
          <Button color="danger" onClick={cancelExchange}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={nestedModal}
        toggle={backExchange}
        // onClosed={closeAll ? toggle : undefined}
      >
        <ModalHeader>Confirmation</ModalHeader>
        <ModalBody>Are you sure you want to get {inputTokensValue} tokens ?</ModalBody>
        <ModalFooter>
          <Button color="success" onClick={completeExchange}>
            Complete
          </Button>{" "}
          <Button color="secondary" onClick={backExchange}>
            Back
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default BuyTokensModal;

import React from "react";
import { defaultTitle } from "../../constants/defaultValues";
import { Link } from "react-router-dom";
import logoFitChain from "../../assets/images/fitchain-no-bg.png";

const Logo = ({ height = "70px", width = "100px" }) => {
  return (
    <>
      <Link to="/" className="d-flex justify-content-center"
            style={{ width: width, height: height, paddingLeft: "8px" }}>
        <div className="" style={{ margin: "auto" }}>
          <img src={logoFitChain} alt="" height={height} style={{ padding: "1px" }} />
        </div>
      </Link>
      {/*<Link to="/" className={"d-flex align-self-center d-none d-lg-block"} style={{ color: "black" }}>*/}
      {/*  <div><b>*/}
      {/*    {defaultTitle}*/}
      {/*  </b>*/}
      {/*  </div>*/}
      {/*</Link>*/}
    </>

  );
};

export default Logo;

export const getCurrentUser = () => {
  let user = null;
  try {
    const authUserObjString = localStorage.getItem("authUser");
    const authUserData = (authUserObjString && JSON.parse(authUserObjString)) || null;
    user = authUserData
  } catch (error) {
    console.log(" getCurrentUser: ", error)
    user = null;
  }
  return user;
}
export const getLoggedIn = () => {
  return !!localStorage.getItem("authUser");
}

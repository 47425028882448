interface MenuItemsProps {
    id: number;
    novidade ?: any;
    label: string;
    icon ?: string;
    link ?: string;
    badge?:string;
    badgecolor?:string;
    subItems ?: any;
    isHeader ?: boolean;
    role?:string;

}

const menuItems: Array<MenuItemsProps> = [
    {
        id: 1,
        label: "Dashboards",
        isHeader : true
    },
    // {
    //     id: 2,
    //     label: "FitChain - Public Home",
    //     icon: "home",
    //     link: "/",
    // },
    {
        id: 222,
        label: "Dashboard",
        icon: "monitor",
        link: "/dashboard",
        badge:"5+",
        badgecolor:"badge rounded-pill badge-secondary-subtle"
    },
    {
        id: 1399,
        label: "Buyer Market",
        icon: "shopping-cart",
        link: "/market",
        role:'buyer',
        badgecolor:"bg-info",
    },
    {
        id: 1379,
        label: "My Purchases",
        icon: "shopping-bag",
        link: "/mypurchases",
        badgecolor:"bg-info",
        role:'buyer'
    },
    {
        id: 1380,
        label: "Permissions",
        icon: "file-text", // clipboard, file-text
        link: "/permissions",
        badgecolor:"bg-info",
        role:'user'
    },
    // {
    //     id: 1381,
    //     label: "Transactions",
    //     icon: "shopping-bag",
    //     link: "/transactions",
    //     badgecolor:"bg-info",
    //     // role:'user'
    // },
    {
        id: 1382,
        label: "Wallet",
        icon: "dollar-sign",
        link: "/wallet",
        badgecolor:"bg-info",
        // role:'user'
    },


    // {
    //     id: 1353463459,
    //     label: "",
    //     // icon: "",
    //     // link: "",
    //     // badgecolor:"bg-info",
    // },
    // {
    //     id: 1359,
    //     label: "REDUX",
    //     icon: "database",
    //     link: "/redux",
    //     badgecolor:"bg-info",
    // },
    // {
    //     id: 139,
    //     label: "Public Page",
    //     icon: "pie-chart",
    //     link: "/stats",
    //     badge:"9",
    //     badgecolor:"bg-info",
    // },
    // {
    //     id: 240,
    //     label: "TEST",
    //     icon: "layout",
    //     link: "/test-home",
    //     badgecolor:"badge rounded-pill badge-secondary-subtle"
    // },




    // {
    //     id: 21,
    //     label: "Pages",
    //     isHeader : true
    // },
    // {
    //     id: 22,
    //     novidade: false,
    //     label: "Personal",
    //     icon: "user",
    //     link: "/#",
    //     subItems: [
    //         {
    //             id: 23,
    //             label: "Sign In",
    //             link: "/#",
    //             parentId: 22,
    //             subItems: [
    //                 {
    //                     id: 24,
    //                     label: "Basic",
    //                     link: "#",
    //                     parentId: 23
    //                 },
    //                 {
    //                     id: 26,
    //                     label: "Cover",
    //                     link: "#",
    //                     parentId: 23
    //                 }
    //             ]
    //         },
    //     ]
    // },
];

export { menuItems };

import React from "react";
import { Col } from "reactstrap";
import { shallowEqual, useSelector } from "react-redux";
import WidgetChartTile from "../../UI/WidgetChartTile";

const WalletWidget = (props) => {

  const { wallet } = useSelector((state => state), shallowEqual);

  const processedData = {
    id: 1,
    title: "WALLET",
    price: wallet.credits ?? 0,
    subtitle: "Total Credits",
    linkButton: "wallet",
    charttype: "bar",
    fotmat: "Monthly",
    series: (wallet && wallet.last_topup) ? wallet.last_topup.map(t => {
      return { x: t.date, y: t.credits };
    }) : [12, 2, 34, 12, 2, 34, 12, 2, 34]
  };


  return (
    <React.Fragment>
      {wallet && wallet.last_topup && wallet.id &&
        <Col xl={12} sm={12}>
          <WidgetChartTile processedData={processedData} />
        </Col>
      }
    </React.Fragment>
  );
};

export default WalletWidget;

import React from "react";
import { Link } from "react-router-dom";

//import components
import SidebarContent from "./SidebarContent";
import SimpleBar from "simplebar-react";

//import images
import logoSm from "../../assets/images/logo-sm-copy.png";
import logoDark from "../../assets/images/logo-dark.png";
import logoLight from "../../assets/images/logo-light.png";
import Logo from "../CommonForBoth/Logo";

const Sidebar = (props: any) => {

  function tToggle() {
    document.body.setAttribute("data-sidebar-size", "sm");
  }

  return (
    <React.Fragment>
      <div className="vertical-menu">
        {/*<div className={"d-flex align-self-center d-none d-lg-block"} >*/}
        {/*  <Logo />*/}
        {/*</div>*/}

        <button
          onClick={() => {
            tToggle();
          }}
          type="button" className="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn" id="vertical-menu-btn">
          <i className="fa fa-fw fa-bars" />
        </button>

        <div className="">
        {/*  <div className="h-100">*/}
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;

import { createSlice } from "@reduxjs/toolkit";
import { getWallet, createWallet, deleteWallet, updateInvoice } from "./thunk";

interface WalletStateType {
  id: string | undefined;
  last_topup: Array<any>;
  credits?: number | null | undefined;
  error?: string | object | null | undefined | unknown;
  isLoading: boolean
}

export const initialState: WalletStateType = {
  id: undefined,
  last_topup: [],
  credits: undefined,
  isLoading:false,
  error: {}
};

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getWallet.fulfilled, (state: any, action: any) => {
      state.credits = action.payload.credits;
      state.last_topup = action.payload.last_topup;
      state.id = action.payload.wallet_id;

    });
    builder.addCase(getWallet.rejected, (state, action) => {
      state.error = action.payload || null;
    });
    builder.addCase(createWallet.fulfilled, (state: any, action: any) => {
      state.id = action.payload.wallet_id;
      state.isLoading = false;
      //state.last_topup=fake_topups
      state.last_topup = [];
      // state.invoices.push(action.payload);
    });
    builder.addCase(createWallet.rejected, (state, action) => {
      state.error = action.payload || null;
      state.isLoading = false;
    });
    builder.addCase(createWallet.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteWallet.fulfilled, (state: any, action: any) => {
      if (action.payload.status === "success") {
        state.id = undefined;
        state.last_topup = [];
        state.credits = undefined;
        state.error = {};
        state.isLoading = false;
      }
    });

    builder.addCase(deleteWallet.rejected, (state, action) => {
      state.error = action.payload || null;
    });
    builder.addCase(deleteWallet.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateInvoice.fulfilled, (state: any, action: any) => {
      state.invoices = (state.invoices || []).map((event: any) =>
        event.id + "" === action.payload.id + ""
          ? { ...event, ...action.payload }
          : event
      );
    });
    builder.addCase(updateInvoice.rejected, (state, action) => {
      state.error = action.payload || null;
    });
  }
});

export default walletSlice.reducer;

const fake_topups= [
  {
    "date": "2024-09-01 11:22:33",
    "credits": 6
  },
  {
    "date": "2024-08-01 11:22:33",
    "credits": 10
  }
  ,{
    "date": "2024-09-01 11:22:33",
    "credits": 12
  },
  {
    "date": "2024-08-01 11:22:33",
    "credits": 7
  },
  {
    "date": "2024-09-01 11:22:33",
    "credits": 16
  },
  {
    "date": "2024-08-01 11:22:33",
    "credits": 10
  }
]

import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardText, CardTitle, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { calculateMetricsData } from "../../../api/api";
import { shallowEqual, useSelector } from "react-redux";

const DEFAULT_GROUP_DATA = {
  potential_total: "-",
  potential_tradable: "-",
  permitted_tradable: "-",
  permitted_tradable_tokens: "-"
}

const MetricGroupItem = ({ group: item, onClickSwitch, onClickCheckbox, onUpdateDate }) => {
  const [itemData, setItemData] = useState(DEFAULT_GROUP_DATA);
  const { profile } = useSelector((state => state), shallowEqual);
  const profileData = profile && profile.success;

  useEffect(() => {
    const data = {
      "domains": [
        {
          "domain": item.title,
          // "from": item.fromDate ? (new Date(item.fromDate)).getTime()/1000 : null,
          // "to": item.toDate ? (new Date(item.toDate)).getTime()/1000 : null
          "from": item.fromDate ? item.fromDate + "  00:00:00" : null,
          "to": item.toDate ? item.toDate + "  23:59:59" : null
        }
      ]
    };
    if (profileData.name)
      calculateMetricsData(profileData.name, data).then(res => {
        // console.log({ res });
        setItemData({ ...res });
      }).catch(e => {
        setItemData(DEFAULT_GROUP_DATA)
      });
    console.log({item});
  }, [item, profileData]);

  return <Col sm="6">
    <Card
      className="my-2"
      color={item.isChecked ? "primary" : "light"}
      // color={"secondary"}
      outline={item.isChecked}
      // inverse={item.isChecked}
      style={{
        minHeight: "15rem",
        // maxHeight: "18rem",
        // width: '18rem'
      }}
    >
      <CardHeader onClick={onClickCheckbox}
                  inverse={true}
                  style={{ cursor: "pointer",
                    color: item.isChecked ?  "white" : undefined,
                    backgroundColor: item.isChecked ? "#2196F3" : "white" }}
                  className={"d-flex flex-row justify-content-between"}
      >
        <div style={{fontWeight:"bold"}}>
          {item.title}
        </div>
        <Input className="form-check-input" type="checkbox" id={`formCheck_` + item.title}
               checked={item.isChecked}
               onChange={onClickCheckbox}
               hidden={true}
        />
        <div hidden={!item.isChecked}><i className="fa fa-check text-white font-size-18"></i></div>
      </CardHeader>

      <CardBody>
        <CardText>
          <Row className={"d-flex flex-row justify-content-center align-items-center"}>
            <div className="form-switch d-flex flex-row justify-content-center"
                 style={{ paddingLeft: 0 }}>
              <Input className="mx-1" type="switch" role="switch" id={`flexSwitchCheckChecked_` + item.title}
                     onChange={onClickSwitch}
                     disabled={!item.isChecked}
                     checked={item.isTimeSpecified} />
              {/*<Label className="form-check-label"*/}
              {/*       htmlFor={"flexSwitchCheckChecked_" + item.title}>*/}
                {item.isTimeSpecified ? "Specify Timeline" : "Not Specified Timeline"}
              {/*</Label>*/}

            </div>
          </Row>
          <Row>
            {item.isTimeSpecified && <>
              <Row>
                <Label htmlFor="fromDate" className="col-form-label mt-2" sm={3}>From</Label>
                <Col sm={9}><Input className="" type="date" value={item.fromDate} id="fromDate"
                                   name={item.title}
                                   onChange={onUpdateDate}
                />
                </Col>
              </Row>
              <Row>
                <Label htmlFor="toDate" className="col-form-label mt-2" sm={3}>To</Label>
                <Col sm={9}><Input className="form-control" type="date" value={item.toDate} id="toDate"
                                   name={item.title}
                                   onChange={onUpdateDate}
                /></Col>
              </Row>
            </>
            }
          </Row>
        </CardText>
        <CardTitle tag="h6">
          <div className={"d-flex flex-row justify-content-between"}>
            <span className={'py-1'}>Metrics</span>
            <span><i className="fa fa-info-circle " title={item.metrics && item.metrics.length > 0 &&
              item.metrics.map(m => m.name).join(",\n")}></i></span>
          </div>
        </CardTitle>
        <CardText>
          <Row>
            <Col md={6}
                 className={"d-flex flex-column align-self-start"}>
              Total: {itemData.potential_total} <br />
              Purchasable (allowed): {itemData.potential_tradable} <br />
              Selected: {itemData.permitted_tradable} <br />
              Selected Metrics Cost: {itemData.permitted_tradable_tokens}
            </Col>
          </Row>
        </CardText>
      </CardBody>
    </Card>
  </Col>
    ;
};

export default MetricGroupItem;

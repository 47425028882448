import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination
} from "react-table";
import { Table, Row, Col, Button, Input, CardBody, PaginationItem, PaginationLink } from "reactstrap";
import { Filter, DefaultColumnFilter } from "./Filter";
import JobListGlobalFilter from "../../components/Common/GlobalSearchFilter";

interface GlobalFilterProps {
  preGlobalFilteredRows?: any;
  globalFilter?: any;
  setGlobalFilter?: any;
  isJobListGlobalFilter?: any;
}

// Define a default UI for filtering
function GlobalFilter({
                        preGlobalFilteredRows,
                        globalFilter,
                        setGlobalFilter,
                        isJobListGlobalFilter
                      }: GlobalFilterProps) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value: any) => {
    setGlobalFilter(value || undefined);
  }, 200);
  return (
    <React.Fragment>
      <Col md={4}>
        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              <input
                onChange={e => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={`${count} records...`}
                value={value || ""}
              />
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
      {isJobListGlobalFilter && (
        <JobListGlobalFilter />
      )}
    </React.Fragment>
  );
}

interface TableContainerProps {
  columns?: any;
  data?: any;
  isGlobalFilter?: any;
  isJobListGlobalFilter?: any;
  isAddOptions?: any;
  isAddUserList?: any;
  handleUserClick?: any;
  handleOrderClicks?: any;
  handleCustomerClick?: any;
  isAddCustList?: any;
  customPageSize?: any;
  className?: any;
  customPageSizeOptions?: any;
  isAddInvoiceList?: any;
  handleInvoiceClick?: any;
}

const TableContainer = ({
                          columns,
                          data,
                          isGlobalFilter,
                          isJobListGlobalFilter,
                          isAddOptions,
                          isAddUserList,
                          handleUserClick,
                          handleOrderClicks,
                          handleCustomerClick,
                          isAddCustList,
                          customPageSize,
                          className,
                          customPageSizeOptions,
                          isAddInvoiceList,
                          handleInvoiceClick
                        }: TableContainerProps) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        sortBy: [
          {
            desc: true
          }
        ]
      }
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  const generateSortingIndicator = (column: any) => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
  };

  const onChangeInSelect = (event: any) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event: any) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  return (
    <Fragment>
      <Row className="mb-2">
        <Col md={customPageSizeOptions ? 2 : 1}>
          <select
            className="form-select"
            value={pageSize}
            onChange={onChangeInSelect}
          >
            {customPageSizeOptions.map((pageSize: number) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        {isGlobalFilter && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            isJobListGlobalFilter={isJobListGlobalFilter}
          />
        )}
        {isAddOptions && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={handleOrderClicks}
              >
                <i className="mdi mdi-plus me-1" />
                Add New Order
              </Button>
            </div>
          </Col>
        )}
        {isAddInvoiceList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                onClick={handleInvoiceClick}
                type="button"
                className="btn btn-light waves-effect waves-light"
              >
                <i className="bx bx-plus me-1"></i> Add Invoice
              </Button>
            </div>
          </Col>
        )}
        {isAddUserList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New User
              </Button>
            </div>
          </Col>
        )}
        {isAddCustList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded mb-2 me-2"
                onClick={handleCustomerClick}
              >
                <i className="mdi mdi-plus me-1" />
                Customers
              </Button>
            </div>
          </Col>
        )}
      </Row>

      <div className="table-responsive react-table">
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
          {headerGroups.map((headerGroup: any) => (
            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((columns: any) => (
                <th key={columns.id}>
                  <div className="mb-2" {...columns.getSortByToggleProps()}>
                    {columns.render("Header")}
                    {generateSortingIndicator(columns)}
                  </div>
                  <Filter column={columns} />
                </th>
              ))}
            </tr>
          ))}
          </thead>

          <tbody {...getTableBodyProps()}>
          {page.map((row: any) => {
            prepareRow(row);
            return (
              <Fragment key={row.getRowProps().key}>
                <tr>
                  {row.cells.map((cell: any) => {
                    return (
                      <td key={cell.id} {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              </Fragment>
            );
          })}
          </tbody>
        </Table>
      </div>
      <Row className="g-0">
        <Col sm={6}>
          <div>
            <p className="mb-sm-0">
              Showing {(pageSize * pageIndex) + 1 } to {Math.min(pageSize * (pageIndex + 1), (data && data.length))} of {(data && data.length) ?? 0} entries
            </p>
          </div>
        </Col>
        <Col sm={6}>
          <div className="float-sm-end">
            <ul className="pagination pagination-rounded mb-sm-0">
              <PaginationItem className={`page-item ${!canPreviousPage && "disabled"}`}>
                <PaginationLink href="#" className="page-link"
                                disabled={!canPreviousPage}
                                onClick={() => previousPage()}
                ><i className="mdi mdi-chevron-left"></i></PaginationLink>
              </PaginationItem>

              {pageOptions.map((page: number) => <PaginationItem key={page}
                                                                 className={`page-item ${pageIndex === page && "active"}`}>
                <PaginationLink href="" className="page-link"
                                onClick={() => gotoPage(page)}
                >{page + 1}</PaginationLink>
              </PaginationItem>)}

              {/*<PaginationItem className="page-item">*/}
              {/*  <PaginationLink href="#" className="page-link">5</PaginationLink>*/}
              {/*</PaginationItem>*/}

              <PaginationItem className={`page-item ${!canNextPage && "disabled"}`}>
                <PaginationLink href="#" className="page-link"
                                disabled={!canNextPage}
                                onClick={() => nextPage()}
                ><i
                  className="mdi mdi-chevron-right"></i></PaginationLink>
              </PaginationItem>

            </ul>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any
};

export default TableContainer;
